<template>
  <sl-icon-button
    class="BzlIconButton"
    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners"></sl-icon-button>
</template>

<script>
  export default {
    name: 'BzlIconButton',
    props: {
      name: {
        type: String,
        required: true,
      },
      label: {
        type: String,
        required: true,
      },
      href: {
        type: String,
        required: false,
        default: null,
      },
      target: {
        type: String,
        required: false,
        default: null,
        validator: (val) => ['_blank', '_parent', '_self', '_top'].includes(val),
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
  }
</script>

<style>
  .BzlIconButton::part(base) {
    @apply p-0;
  }

  .BzlAlert[variant='warning']::part(close-button__base) {
    padding-bottom: 0;
  }
</style>
