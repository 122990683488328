const validators = {}

validators.isDefined = (i) => {
  return !(typeof i === 'undefined' || i === null)
}

validators.isBoolean = (i) => {
  return typeof i === 'boolean'
}

validators.isNumber = (i) => {
  return typeof i === 'number' && i >= 0
}

validators.isPositiveNumber = (i) => {
  return validators.isNumber(i) && i > 0
}

validators.isArray = (i) => {
  // an empty array is valid
  return typeof i === 'object' && i instanceof Array
}

validators.isValidString = (i) => {
  return typeof i === 'string' && i.trim().length > 0
}

validators.isArrayOfValidStrings = (i) => {
  // an empty array is valid
  return validators.isArray(i) && i.every((i) => validators.isValidString(i))
}

validators.isAtLeastXChars = (i, { min }) => {
  return validators.isValidString(i) && i.length >= min
}

validators.isArrayOf = (i, { className }) => {
  return validators.isArray(i) && i.every((i) => i instanceof className)
}

export const validate = (o, fields) => {
  let isValid = true

  try {
    for (const key in fields) {
      const value = o[key]
      const validations = fields[key]
      const fieldIsRequired = validations.includes('isDefined')

      // if an optional value is undefined, just skip it
      if (!fieldIsRequired && !validators.isDefined(value)) continue

      for (const v of validations) {
        // if "v" is a function, it's a custom validator!
        if (typeof v === 'function') {
          isValid = v(value)
        } else if (typeof v === 'object') {
          // if "v" is an object, it's a generic validator with params
          isValid = validators[v.name](value, v.payload)
        } else if (typeof v === 'string') {
          // otherwise, it's just a generic validator, no params
          isValid = validators[v](value)
        }

        if (!isValid) throw new Error(`{ ${key}: ${value} } fails validation: ${v?.name || v}`)
      }
    }
  } catch (e) {
    console.error(e)
  }

  return isValid
}

export default {
  ...validators,
  validate,
}
