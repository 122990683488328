export const ADD_OFFERS = '/api/change-order/offers'
export const CHANGE_ORDER_INITIATE = '/api/change-order/initiate'
export const GET_ADDITIONAL_CHANNELS = '/api/change-order/additional-channels'
export const GET_ADDITIONAL_EQUIPMENT = '/api/change-order/additional-equipment'
export const GET_APPOINTMENTS = '/api/change-order/appointments'
export const GET_CART = '/api/change-order/cart'
export const GET_CREDITS = '/api/change-order/credits'
export const GET_EQUIPMENT = '/api/change-order/equipment'
export const GET_INTERNET = '/api/change-order/internet'
export const GET_LOCALS = '/api/change-order/locals'
export const GET_ORDER = '/api/change-order/order'
export const GET_OTHER_SERVICES = '/api/change-order/other-services'
export const GET_PRODUCTS = '/api/change-order/products'
export const GET_SHIPPING = '/api/change-order/shipping'
export const GET_STREAMING_SERVICES = '/api/change-order/streaming-services'
export const GET_SUMMARY = '/api/change-order/summary'
export const GET_SUPERPODS = '/api/change-order/superpods'
export const GET_TIVO = '/api/change-order/tivo'
export const GET_VOICE = '/api/change-order/voice'
export const GET_WIFI = '/api/change-order/wifi'
export const POST_APPOINTMENTS = '/api/change-order/appointments'
export const POST_EQUIPMENT = '/api/change-order/equipment'
export const POST_INTERNET = '/api/change-order/internet'
export const POST_OFFER_CONFIGURATION = '/api/change-order/offer-configuration'
export const POST_ORDER = '/api/change-order/order'
export const POST_SUPERPODS = '/api/change-order/superpods'
export const POST_WIFI = '/api/change-order/wifi'

export default {
  ADD_OFFERS,
  CHANGE_ORDER_INITIATE,
  GET_ADDITIONAL_CHANNELS,
  GET_ADDITIONAL_EQUIPMENT,
  GET_APPOINTMENTS,
  GET_CART,
  GET_CREDITS,
  GET_EQUIPMENT,
  GET_INTERNET,
  GET_LOCALS,
  GET_ORDER,
  GET_OTHER_SERVICES,
  GET_PRODUCTS,
  GET_SHIPPING,
  GET_STREAMING_SERVICES,
  GET_SUMMARY,
  GET_SUPERPODS,
  GET_TIVO,
  GET_VOICE,
  GET_WIFI,
  POST_APPOINTMENTS,
  POST_EQUIPMENT,
  POST_INTERNET,
  POST_OFFER_CONFIGURATION,
  POST_ORDER,
  POST_SUPERPODS,
  POST_WIFI,
}
