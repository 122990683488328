export default [
  'AACSTOWN',
  'ACWA',
  'AHDG2470',
  'AHDPC3848',
  'AHTM1602',
  'AIPE',
  'AUPTC',
  'AUPTD',
  'AUPTG',
  'AUPTH',
  'AUWEJ',
  'AUWEK',
  'AUWEL',
  'AUWEV',
  'AUWEW',
  'AUWHB',
  'AUWHC',
  'AUWIB',
  'CDA335',
  'CGA4234',
  'CGA4236',
  'CGM4231',
  'CGNM2250',
  'CGNM2252',
  'CGNVM2559',
  'CGVM2559',
  'CM3200A',
  'CM8200A',
  'CODA4589',
  'CODA4589B',
  'CVA4003',
  'D5001',
  'PON803G',
  'PON844G',
  'PON854G',
  'TC4400',
  'TG2472',
  'TG2472G',
  'TG3452',
  'TG3452A',
]
