<template>
  <sl-divider
    class="BzlDivider"
    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners"></sl-divider>
</template>

<script>
  // https://shoelace.style/components/divider
  import '@shoelace-style/shoelace/dist/components/divider/divider.js'

  export default {
    name: 'BzlDivider',
    props: {
      vertical: {
        type: Boolean,
        default: false,
      },
      // updateComplete: {}
    },
  }
</script>
