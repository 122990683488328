import { validate } from './validators.js'

export class InternetService {
  constructor(o) {
    if (InternetService.validate(o)) {
      this.id = o.id
      this.title = o.title
      this.titleInCart = o.titleInCart
      this.downloadSpeed = o.downloadSpeed
      this.uploadSpeed = o.uploadSpeed
      this.dataCap = o.dataCap
      this.monthlyPrice = o.monthlyPrice
      this.preCreditPrice = o.preCreditPrice
      this.idealFor = o.idealFor
      this.keyPoints = o.keyPoints
      this.modems = o.modems
    }
  }

  static validate(o) {
    return validate(o, {
      id: ['isDefined', 'isValidString'],
      title: ['isDefined', 'isValidString'],
      titleInCart: ['isDefined', 'isValidString'],
      monthlyPrice: [
        'isDefined',
        // the currently selected speed doesn't always have a price associated
        // with it so it needs to be isNumber rather than isPositiveNumber
        'isNumber',
      ],
      downloadSpeed: ['isDefined', 'isPositiveNumber'],
      uploadSpeed: ['isDefined', 'isPositiveNumber'],
      dataCap: ['isPositiveNumber'],
      idealFor: ['isValidString'],
      keyPoints: ['isArrayOfValidStrings'],
      modems: ['isDefined', 'isArrayOfValidStrings'],
    })
  }
}
