import { InternetService } from '$/InternetService.js'
import { prepareInternetServices } from '../utils.js'
import axios from 'axios'
import endpoints from './endpoints.js'

export default {
  namespaced: true,

  state: {
    // filled by API
    currentService: null,
    availableServices: [],
    requiresModemUpgrade: false,
    offerConfigured: false,
  },

  getters: {},

  mutations: {
    SET_SERVICES(state, { currentServiceId = '', allServices = [] }) {
      try {
        if (currentServiceId.length === 0 || allServices.length === 0)
          throw new Error(`Missing internet services data.`)

        state.currentService = allServices.find((s) => s.id === currentServiceId)
        if (!state.currentService)
          throw new Error(`Unable to find current internet service from provided list.`)

        state.currentService = new InternetService(state.currentService)
        state.availableServices = allServices
          .filter((s) => s.id !== currentServiceId)
          .map((s) => new InternetService(s))
      } catch (e) {
        throw new Error('Unable to parse available internet services', { cause: 'api' })
      }
    },
    SET_CURRENT_SERVICE(state, { currentServiceId = '', allServices = [] }) {
      try {
        if (currentServiceId.length === 0 || allServices.length === 0)
          throw new Error(`Missing internet services data.`)

        state.currentService = allServices.find((s) => s.id === currentServiceId)
        if (!state.currentService)
          throw new Error(`Unable to find current internet service from provided list.`)

        state.currentService = new InternetService(state.currentService)
        state.availableServices = allServices
          .filter((s) => s.id !== currentServiceId)
          .map((s) => new InternetService(s))

        // todo: set state.requiresModemUpgrade based on user's current modem
        // state.requiresModemUpgrade = true // temp
      } catch (e) {
        console.error(e)
      }
    },
    SET_OFFER_CONFIGURED(state, value) {
      state.offerConfigured = value
    },
    SET_REQUIRES_MODEM_UPGRADE(state, value) {
      state.requiresModemUpgrade = value
    },
  },

  actions: {
    initialize({ dispatch }) {
      //
    },
    async getServices({ commit, rootState, dispatch }) {
      dispatch('lockApp', null, { root: true })

      const response = axios
        .get(endpoints.GET_INTERNET, {
          params: {
            uuid: rootState.account.uuid,
            conversationId: rootState.account.conversationId,
          },
        })
        .then((response) => {
          if (response.data.data) {
            commit('SET_SERVICES', prepareInternetServices(response.data.data.internet.options))
          }

          dispatch('unlockApp', null, { root: true })
        })
        .catch((error) => {
          return error
        })

      return response
    },

    async submitInternet({ commit, state, rootState, dispatch }, internet) {
      commit('LOCK_APP', null, { root: true })

      const data = {
        uuid: rootState.account.uuid,
        conversationId: rootState.account.conversationId,
        selected: internet.title,
      }

      return axios
        .post(endpoints.POST_INTERNET, data)
        .then((response) => {
          commit('SET_OFFER_CONFIGURED', true)
          commit('UNLOCK_APP', null, { root: true })
        })
        .catch((error) => {
          const message = error.response.data.message
          throw message
        })
    },

    determineIfModemUpgradeIsRequired({ commit, state, rootState }, service) {
      if (!service) {
        commit('SET_REQUIRES_MODEM_UPGRADE', false)
        return
      }

      commit('SET_REQUIRES_MODEM_UPGRADE', !service.modems.includes(rootState.account.modem))
    },

    setServices({ commit, state, rootState, dispatch }, availableSpeeds) {},
  },
}
