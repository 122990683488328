import Vue from 'vue'
import VueRouter from 'vue-router'
import baseRoutes from '#/routes'

import ChangeYourSpeedPage from './pages/ChangeYourSpeedPage.vue'
import HardwareRequirementPage from './pages/HardwareRequirementPage.vue'
import CartPage from './pages/CartPage.vue'
import ConfirmationPage from './pages/ConfirmationPage.vue'
import ManualUpgradePage from './pages/ManualUpgradePage.vue'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes: [
    {
      path: '/',
      redirect: '/change-your-speed',
    },
    {
      name: 'change-your-speed',
      path: '/change-your-speed',
      components: {
        main: ChangeYourSpeedPage,
      },
      beforeEnter,
    },
    {
      name: 'hardware-requirement',
      path: '/hardware-requirement',
      components: {
        main: HardwareRequirementPage,
      },
      beforeEnter,
    },
    {
      name: 'cart',
      path: '/cart',
      components: {
        main: CartPage,
      },
      beforeEnter,
    },
    {
      name: 'confirmation',
      path: '/confirmation',
      components: {
        main: ConfirmationPage,
      },
      beforeEnter,
    },
    {
      name: 'manual-upgrade',
      path: '/manual-upgrade',
      components: {
        main: ManualUpgradePage,
      },
      beforeEnter,
    },

    // maintenance and error pages
    ...baseRoutes,

    {
      // 404/catch-all
      path: '*',
      redirect: '/change-your-speed',
    },
  ],
})

// always smooth-scroll to top of the page
function beforeEnter(to, from, next) {
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth',
  })
  next()
}

if (import.meta.env.PROD) {
  router.afterEach((to) => {
    window.gtag?.('config', import.meta.env.VITE_GTM_TRACKING_ID, {
      page_path: to.path,
      page_location: String(window.location),
      app_name: import.meta.env.VITE_GA_APP_NAME,
      send_page_view: true,
    })
  })
}

export default router
