import { loadMainApp } from '../../index.js'
import app from './App.vue'
import router from './router.js'
import store from './store/index.js'

import '#/plugins/sentry.js'
import '#/plugins/gtm.js'
import '#/plugins/calendar.js'
import '#/shared/index.js'
import '#/plugins/hotjar.js'

import Vue from 'vue'
import { setBasePath } from '@shoelace-style/shoelace/dist/utilities/base-path'
import './theme.css'

loadMainApp({ app, router, store, authType: 'user' })

// Globally register all "Bzl[*]" components
const components = import.meta.glob('./components/App**/index.vue', { eager: true })
for (const key in components) {
  Vue.component(components[key].default.name, components[key].default)
}

// For shoelace icons, etc
// todo: on build, copy "@shoelace-style/shoelace/dist/assets" to "[app]/public" and set that as base path
setBasePath('https://cdn.jsdelivr.net/npm/@shoelace-style/shoelace@2.0.0-beta.80/dist/')
