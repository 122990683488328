<template>
  <sl-checkbox
    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners">
    <slot></slot>
  </sl-checkbox>
</template>

<script>
  import '@shoelace-style/shoelace/dist/components/checkbox/checkbox.js'
  export default {
    name: 'BzlCheckbox',
    props: {
      name: {
        type: String,
        default: '',
      },
      value: {
        type: String,
        default: '',
      },
      size: {
        type: String,
        default: 'medium',
        validator: (val) => ['small', 'medium', 'large'].includes(val),
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      checked: {
        type: Boolean,
        default: false,
      },
      indeterminate: {
        type: Boolean,
        default: false,
      },
      defaultChecked: {
        type: Boolean,
        default: false,
      },
      form: {
        type: String,
        default: '',
      },
      required: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>
