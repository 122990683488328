<template>
  <div class="flex h-screen flex-row items-center justify-center">
    <div class="w-1/2 space-y-4 rounded bg-white p-8 text-center shadow">
      <h1 class="text-xl font-bold text-error">
        <i class="fas fa-info-circle"></i> Please enable 3rd-party cookies to use the My Account web
        application.
      </h1>
      <p>Instructions for major browsers are provided below:</p>
      <div class="flex w-full flex-col justify-evenly lg:flex-row">
        <a
          href="https://support.apple.com/guide/safari/manage-cookies-sfri11471/mac"
          target="_blank"
          rel="noreferrer noopener">
          <i class="fab fa-safari"></i>
          <span>Safari</span>
        </a>
        <a
          href="https://support.google.com/chrome/answer/95647"
          target="_blank"
          rel="noreferrer noopener">
          <i class="fab fa-chrome"></i>
          <span>Chrome</span>
        </a>
        <a
          href="https://support.mozilla.org/en-US/kb/third-party-cookies-firefox-tracking-protection#w_enable-third-party-cookies-for-specific-sites"
          target="_blank"
          rel="noreferrer noopener">
          <i class="fab fa-firefox"></i>
          <span>Firefox</span>
        </a>
        <a
          href="https://support.microsoft.com/en-us/microsoft-edge/issue-with-blocking-third-party-cookies-f91209bd-0104-2446-61be-d2e4d7da64da"
          target="_blank"
          rel="noreferrer noopener">
          <i class="fab fa-edge"></i>
          <span>Edge</span>
        </a>
      </div>

      <div class="or-divider relative my-4 text-center font-bold">
        <span class="relative z-10 inline-block bg-white p-4 text-xl">or</span>
      </div>

      <h1 class="text-xl font-bold text-error">
        <i class="fas fa-mobile-alt"></i> Download our mobile apps.
      </h1>
      <p>Available on:</p>
      <div class="flex w-full flex-col justify-evenly lg:flex-row">
        <a
          href="https://play.google.com/store/apps/details?id=com.breezeline"
          target="_blank"
          rel="noreferrer noopener">
          <i class="fab fa-android"></i>
          <span>Android</span>
        </a>
        <a
          href="https://apps.apple.com/us/app/my-breezeline/id1568553022"
          target="_blank"
          rel="noreferrer noopener">
          <i class="fab fa-apple"></i>
          <span>iOS</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CookieAccessErrorPage',
    mounted() {
      window.Sentry?.captureMessage('Client browser does not allow 3rd-party cookies!')
    },
  }
</script>

<style scoped>
  a {
    @apply text-navy underline;
  }

  a span {
    @apply ml-2;
  }

  .or-divider:before {
    content: '';
    @apply absolute left-0 top-1/2 h-[2px] w-full bg-black;
  }
</style>
