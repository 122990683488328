import axios from 'axios'
import { CHANGE_ORDER_INITIATE } from './endpoints'
import gql from 'graphql-tag'

export default {
  namespaced: true,

  state: {
    accountNumber: null,
    // loginName: null,
    loginEmail: null,
    uuid: null,
    conversationId: null,
    currentOffer: null,
    modem: null,
    hsdInfo: [],
    currentPlan: {
      code: null,
      down: null,
      name: null,
      up: null,
    },
    customerInfo: {
      firstName: null,
      lastName: null,
      salutation: null,
      emailAddress: null, // aka preferred email
      homePhone: null,
      businessPhone: null,
    },
    // setting this to true will force user to the page where they should manually call in/chat to upgrade
    manualUpgradeRequired: false,
    address: null,
  },

  getters: {
    loginName(state) {
      return state.customerInfo.firstName + ' ' + state.customerInfo.lastName
    },
  },

  mutations: {
    SET_ACCOUNT_NUMBER(state, value) {
      state.accountNumber = value
    },
    SET_CONVERSATION_ID(state, value) {
      state.conversationId = value
    },
    SET_UUID(state, value) {
      state.uuid = value
    },
    SET_CURRENT_OFFER(state, value) {
      state.currentOffer = value
    },
    SET_CUSTOMER_INFO(state, value) {
      state.customerInfo = value
    },
    SET_HSD_INFO(state, value) {
      this.hsdInfo = value
    },
    SET_MODEM(state, value) {
      state.modem = value
    },
    SET_CURRENT_PLAN(state, value) {
      state.currentPlan = value
    },
    SET_ADDRESS(state, value) {
      state.address = value
    },
  },

  actions: {
    async getAccountNumber({ commit }) {
      return window.$apollo
        .query({
          query: gql`
            query getAccountNumber {
              getAccountNumber
            }
          `,
        })
        .then((response) => {
          if (!response.data?.getAccountNumber) {
            throw new Error('Unable to get account number', { cause: 'graphql' })
          }

          commit('SET_ACCOUNT_NUMBER', response.data.getAccountNumber)
        })
    },
    async getAddress({ commit }) {
      return window.$apollo
        .query({
          query: gql`
            query getServiceAddress {
              getServiceAddress {
                addressLine1
                addressLine2
                city
                postalCode
                state
              }
            }
          `,
        })
        .then((response) => {
          if (!response.data?.getServiceAddress) {
            throw new Error('Unable to get service address', { cause: 'graphql' })
          }

          commit('SET_ADDRESS', response.data.getServiceAddress)
        })
    },
    async getCustomerInfo({ commit }) {
      return window.$apollo
        .query({
          query: gql`
            query getCustomerInfo {
              getCustomerInfo {
                firstName
                lastName
                salutation
                emailAddress
                homePhone
                businessPhone
              }
            }
          `,
        })
        .then((response) => {
          if (!response.data.getCustomerInfo) {
            throw new Error('Unable to load customer information', { cause: 'graphql' })
          }

          if (response.data.getCustomerInfo) {
            commit('SET_CUSTOMER_INFO', response.data.getCustomerInfo)
            window.localStorage.setItem(
              'customerInfo',
              JSON.stringify(response.data.getCustomerInfo)
            )
          }
        })
    },
    async getHsdInfo({ commit, dispatch }) {
      return window.$apollo
        .query({
          query: gql`
            query getHsdInfo {
              getHsdInfo {
                hsdCurrentPriceStv
                hsdEverydayPriceStv
                hsdProductCode
                hsdProductName
                hsdPromotionEnabled
                hsdPromotionExpirationDate
                hsdSpeedMpbs
                hsdSpeedUpMbps
              }
            }
          `,
        })
        .then((response) => {
          if (!response.data.getHsdInfo.length) {
            throw new Error('Unable to load current plan information', { cause: 'graphql' })
          }
          commit('SET_HSD_INFO', response.data.getHsdInfo)
          dispatch('parseHsdInfo', response.data.getHsdInfo)
        })
        .catch(() => {
          throw new Error('Unable to load current plan information', {
            cause: 'graphql',
          })
        })
    },
    async getEquipment({ commit, dispatch }) {
      return window.$apollo
        .query({
          query: gql`
            query getEquipment {
              getEquipment {
                category
                make
                model
                serialNumber
                status
                type
              }
            }
          `,
        })
        .then((response) => {
          if (!response.data.getEquipment.length) {
            throw new Error('Unable to find current equipment', { cause: 'graphql' })
          }

          const modem = response.data.getEquipment[0].model.trim()

          window.localStorage.setItem('modem', modem)
          commit('SET_MODEM', modem)
        })
    },

    async initialize({ state, commit, dispatch }) {
      dispatch('lockApp', null, { root: true })

      let bailOut = false

      const account = {
        // somewhere along the way the account number in local storage gets set
        // to "null". Parse it as json so we get an actual null value rather than
        // a string of "null" which breaks the check below.
        //
        // not sure exactly how the account number gets set as null.
        accountNumber: JSON.parse(window.localStorage.getItem('accountNumber')),
        uuid: window.localStorage.getItem('uuid'),
        conversationId: window.localStorage.getItem('conversationId'),
        currentOffer: window.localStorage.getItem('currentOffer'),
        currentPlan: window.localStorage.getItem('currentPlan'),
      }

      try {
        if (!account.accountNumber || !state.accountNumber) {
          await dispatch('getAccountNumber')
        }
      } catch (error) {
        bailOut = error
      }

      if (bailOut) {
        throw bailOut
      }

      if (
        account.accountNumber &&
        account.uuid &&
        account.conversationId &&
        account.currentOffer &&
        account.currentPlan
      ) {
        commit('SET_ACCOUNT_NUMBER', account.accountNumber)
        commit('SET_CONVERSATION_ID', account.conversationId)
        commit('SET_CURRENT_OFFER', account.currentOffer)
        commit('SET_UUID', account.uuid)
        commit('SET_CURRENT_PLAN', JSON.parse(account.currentPlan))
        return
      }

      dispatch('clearLocalStorage')

      window.localStorage.setItem('accountNumber', state.accountNumber)

      return axios
        .get(CHANGE_ORDER_INITIATE, {
          params: {
            accountId: state.accountNumber,
          },
        })
        .then((response) => {
          commit('SET_CONVERSATION_ID', response.data.data.conversationId)
          commit('SET_UUID', response.data.data.uuid)
          commit('SET_CURRENT_OFFER', response.data.data.currentOffer)

          // store session stuff in local storage
          window.localStorage.setItem('uuid', state.uuid)
          window.localStorage.setItem('conversationId', state.conversationId)
          window.localStorage.setItem('currentOffer', state.currentOffer)
        })
        .catch((error) => {
          throw new Error(error?.response?.data?.message, { cause: 'api' })
        })
        .then(() => {
          commit('UNLOCK_APP', null, { root: true })
        })
    },

    async getAccountData({ dispatch, commit }) {
      if (!window.localStorage.getItem('customerInfo')) {
        await dispatch('getCustomerInfo')
      } else {
        const customerInfo = window.localStorage.getItem('customerInfo')
        commit('SET_CUSTOMER_INFO', JSON.parse(customerInfo))
      }

      if (!window.localStorage.getItem('currentPlan')) {
        await dispatch('getHsdInfo')
      } else {
        const plan = window.localStorage.getItem('plan')
        commit('SET_CURRENT_PLAN', JSON.parse(plan))
      }

      if (!window.localStorage.getItem('modem')) {
        await dispatch('getEquipment')
      } else {
        commit('SET_MODEM', window.localStorage.getItem('modem'))
      }
    },

    parseHsdInfo({ state, commit }, hsdInfo) {
      const info = hsdInfo.find((info) => info.hsdProductCode.toLowerCase().includes('air'))

      if (info) {
        const plan = {
          code: info.hsdProductCode,
          down: info.hsdSpeedMpbs,
          name: info.hsdProductName,
          up: info.hsdSpeedUpMbps,
        }

        commit('SET_CURRENT_PLAN', plan)
        window.localStorage.setItem('currentPlan', JSON.stringify(plan))
      } else {
        commit('SET_CURRENT_PLAN', {
          code: null,
          down: null,
          name: null,
          up: null,
        })
      }
    },

    logout({ commit, dispatch }) {
      dispatch('lockApp', null, { root: true })

      window.$apollo
        .mutate({
          mutation: gql`
            mutation {
              logout
            }
          `,
        })
        .then((r) => {
          if (r.data?.logout) {
            // allow time for the mutation to clear the cookies
            setTimeout(() => {
              dispatch('clearLocalStorage')
              window.location.href = decodeURIComponent(r.data.logout)
            }, 1000)
          }
        })
        .catch(() => {
          // window.Sentry.captureException(new Error('Unable to log user out'))
        })
    },

    clearLocalStorage() {
      window.localStorage.removeItem('accountNumber')
      window.localStorage.removeItem('originalInternetOfferConfig')
      window.localStorage.removeItem('uuid')
      window.localStorage.removeItem('conversationId')
      window.localStorage.removeItem('modem')
      window.localStorage.removeItem('currentOffer')
      window.localStorage.removeItem('currentPlan')
      window.localStorage.removeItem('customerInfo')
    },
  },
}
