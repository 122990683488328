<template>
  <div
    class="fixed inset-0 z-50 flex h-full w-full items-center justify-center bg-black bg-opacity-75">
    <img
      src="./breezeline_pinwheel_white.png"
      class="animate-spin" />
  </div>
</template>

<script>
  export default {
    name: 'SiteLoader',
  }
</script>
