import axios from 'axios'
import endpoints from './endpoints.js'
import { InternetAddOn } from '$/InternetAddOn.js'

export default {
  namespaced: true,

  state: {
    selfInstallKitId: null,
    gatewayLeaseId: null,
    gatewayLeaseSwapId: null,
    hsdPhoneModemSwapId: null,
    modemSwapAoId: null,
    options: null,
  },

  getters: {
    //
  },

  mutations: {
    SET_OPTIONS(state, options) {
      state.options = options

      options.forEach((option) => {
        // formerly matching this based on the option name 'SIK - Self Install Kit'
        // but BZL at some point changed it.
        if (option.id === 13192) {
          state.selfInstallKitId = option.id
        }

        if (option.name === 'Gateway Lease') {
          state.gatewayLeaseId = option.id
        }

        if (option.name === 'Gateway Lease Swap') {
          state.gatewayLeaseSwapId = option.id
        }

        if (option.name === 'HSD/Phone Modem Swap') {
          state.hsdPhoneModemSwapId = option.id
        }

        // formerly matching this based on the option name 'Modem Swap AO'
        // but BZL at some point changed it.
        if (option.id === 14670) {
          state.modemSwapAoId = option.id
        }
      })
    },
    SET_SELF_INSTALL_KIT_ID(state, id) {
      state.selfInstallKitId = id
    },
    SET_GATEWAY_LEASE_ID(state, id) {
      state.gatewayLeaseId = id
    },
    SET_GATEWAY_LEASE_SWAP_ID(state, id) {
      state.gatewayLeaseSwapId = id
    },
    SET_HSD_PHONE_MODEM_SWAP_ID(state, id) {
      state.hsdPhoneModemSwapId = id
    },
  },

  actions: {
    async getProducts({ state, commit, dispatch, rootState }) {
      commit('LOCK_APP', null, { root: true })
      const params = {
        uuid: rootState.account.uuid,
        conversationId: rootState.account.conversationId,
      }

      return axios
        .get(endpoints.GET_PRODUCTS, {
          params,
        })
        .then((response) => {
          commit('SET_OPTIONS', response.data.data.products)
          commit('UNLOCK_APP', null, { root: true })
        })
        .catch((error) => {
          dispatch('handleError', error, { root: true })
          // throw new Error(error.response.data.message, { cause: 'api' })
        })
    },

    async addSelfInstallKit({ commit, state, rootState, dispatch }) {
      dispatch('lockApp', null, { root: true })

      const data = {
        uuid: rootState.account.uuid,
        conversationId: rootState.account.conversationId,
        offer: parseInt(rootState.account.currentOffer),
        equipment: [rootState.products.selfInstallKitId, rootState.products.modemSwapAoId],
      }

      return axios
        .post(endpoints.ADD_OFFERS, data)
        .then((response) => {
          dispatch(
            'cart/addAddOn',
            {
              type: 'installation',
              addOn: new InternetAddOn({
                id: 'self-install-kit',
                category: 'feature',
                title: 'Self Install Kit',
                titleInCart: 'Self Install Kit',
                monthlyPrice: 0,
                isEditable: false,
              }),
              quantity: 1,
            },
            { root: true }
          )
        })
        .catch((error) => {
          dispatch('handleError', error, { root: true })
        })
        .then(() => {
          dispatch('unlockApp', null, { root: true })
        })
    },

    async removeSelfInstallKit({ commit, state, rootState, dispatch }) {
      dispatch('lockApp', null, { root: true })

      const data = {
        uuid: rootState.account.uuid,
        conversationId: rootState.account.conversationId,
        offer: parseInt(rootState.account.currentOffer),
        equipment: [],
      }

      return axios
        .post(endpoints.ADD_OFFERS, data)
        .then((response) => {
          dispatch(
            'cart/removeAddOn',
            {
              type: 'installation',
              addOn: new InternetAddOn({
                id: 'self-install-kit',
                category: 'feature',
                title: 'Self Install Kit',
                titleInCart: 'Self Install Kit',
                monthlyPrice: 0,
                isEditable: false,
              }),
            },
            { root: true }
          )
        })
        .catch((error) => {
          dispatch('handleError', error, { root: true })
        })
        .then(() => {
          dispatch('unlockApp', null, { root: true })
        })
    },

    async addGatewayLeaseSwap({ commit, state, rootState, dispatch }) {
      dispatch('lockApp', null, { root: true })

      const data = {
        uuid: rootState.account.uuid,
        conversationId: rootState.account.conversationId,
        offer: parseInt(rootState.account.currentOffer),
        equipment: [rootState.products.gatewayLeaseSwapId],
      }

      return axios
        .post(endpoints.ADD_OFFERS, data)
        .then((response) => {
          // I don't think we actually need to do anything with the data we
          // get back with this call since we already have the initial offer
        })
        .catch((error) => {
          dispatch('handleError', error, { root: true })
        })
        .then(() => {
          dispatch('unlockApp', null, { root: true })
        })
    },

    async addHsdPhoneSwap({ commit, state, rootState, dispatch }) {
      dispatch('lockApp', null, { root: true })

      const data = {
        uuid: rootState.account.uuid,
        conversationId: rootState.account.conversationId,
        offer: parseInt(rootState.account.currentOffer),
        equipment: [rootState.products.hsdPhoneModemSwapId],
      }

      return axios
        .post(endpoints.ADD_OFFERS, data)
        .then((response) => {
          dispatch(
            'cart/addAddOn',
            {
              type: 'installation',
              addOn: new InternetAddOn({
                id: 'phone-modem-swap',
                category: 'feature',
                title: 'Phone Modem Swap',
                titleInCart: 'Phone Modem Swap',
                monthlyPrice: 0,
              }),
              quantity: 1,
            },
            { root: true }
          )
        })
        .catch((error) => {
          dispatch('handleError', error, { root: true })
        })
        .then(() => {
          dispatch('unlockApp', null, { root: true })
        })
    },
  },
}
