<template>
  <main class="space-y-4 pb-36">
    <BzlTitlePair centered>
      <template #title>All Done!</template>
    </BzlTitlePair>

    <AppPanel>
      <div class="text-lg font-bold">
        Thank you {{ $store.state.account.loginName }}! You’ve successfully completed your order!
      </div>
      <div>
        Your account number is
        <span class="font-bold text-salmon">{{ $store.state.account.accountNumber }}</span
        >. To save this order confirmation, please print or save this page as a PDF by clicking on
        the “Print a Receipt” button below.
      </div>
      <BzlButton @click="printReceipt">
        <BzlIcon name="printer"></BzlIcon>
        Print a receipt
      </BzlButton>
      <AppPanel
        v-if="showDiscountBanner"
        variant="alternate">
        <div class="font-bold">Please activate your $10 AutoPay and Ecosave discount!</div>
        <div>
          To ensure you continue saving $10 per month on your bill, please sign up for AutoPay and
          ebill by clicking on the sign up button below. Once you sign up please save your username
          and password. You will need these credentials in the future to view/pay your bill online
          and manage your account.
        </div>
        <BzlButton
          variant="primary"
          :href="$store.getters['myAccountUrl'] + '/billing?enable_autopay=true'"
          @click="enableAutoPayClicked">
          Sign up for AutoPay now!
        </BzlButton>
      </AppPanel>
    </AppPanel>

    <AppPanel>
      <div class="text-lg font-bold">Your Services</div>
      <div class="grid grid-cols-4 items-stretch gap-4">
        <template v-for="s in services">
          <div
            v-if="s.items.length > 0"
            :key="s.title"
            class="pool col-span-full shadow lg:col-span-2 lg:shadow-none xl:col-span-1">
            <div class="font-bold">{{ s.title }}</div>
            <ul class="mt-2 list-inside list-disc text-sm">
              <li
                v-for="i in s.items"
                :key="i[0].title">
                {{ i[0].title }}
                <AppCountBadge v-if="i[1] > 1">
                  {{ i[1] }}
                </AppCountBadge>
              </li>
            </ul>
          </div>
        </template>
      </div>
      <div class="font-bold">
        Please note: If new hardware is required, please note speed will not take affect until new
        hardware is activated.
      </div>

      <div>
        When your service is updated you may lose internet and home phone service for a moment. If
        your service does not resume within five minutes, please unplug the modem or Gateway power
        cord from the electrical power outlet, wait one full minute, and plug the power cord back
        into the modem or gateway. It should automatically come back online. If your service does
        not resume, please contact customer service at
        <a
          class="underline"
          :href="`tel:${$store.state.customerServicePhoneNumber}`"
          v-text="$store.state.formattedCustomerServicePhoneNumber"></a
        >.
      </div>
    </AppPanel>

    <AppWizardNav
      color="dark"
      inner-class="justify-center">
      <BzlButton
        variant="default"
        size="large"
        :href="$store.getters['myAccountUrl']"
        target="_self">
        <BzlIcon name="house-door-fill"></BzlIcon>
        Back to My Account
      </BzlButton>
    </AppWizardNav>
  </main>
</template>

<script>
  export default {
    name: 'ConfirmationPage',
    data: () => ({}),
    computed: {
      total() {
        const monthly = this.$store.state.order.order.orderSummary.accounts[0].monthlyCharge
        const localTax = this.$store.state.order.order.orderSummary.taxFees

        return monthly + localTax
      },
      services() {
        return [
          this.internetServices,
          this.tvServices,
          this.voiceServices,
          this.installationServices,
        ]
      },
      internetServices() {
        const internet = {
          title: 'Internet',
          items: [],
        }

        internet.items.push([{ title: this.$store.state.cart.services.internet.title }, 1])

        internet.items = internet.items.concat(
          this.$store.state.cart.addOns.internet.map((addOn) => [
            { title: addOn[0].title },
            addOn[1],
          ])
        )

        return internet
      },
      tvServices() {
        const tv = {
          title: 'TV',
          items: [],
        }

        if (this.$store.state.order.order.locals) {
          this.$store.state.order.order.locals.options.forEach((service) => {
            if (service.selected) {
              tv.items.push([{ title: service.name }, service.quantity ? service.quantity : 1])
            }
          })
        }

        if (this.$store.state.order.order.tivo) {
          this.$store.state.order.order.tivo.options.forEach((service) => {
            if (service.selected) {
              tv.items.push([{ title: service.name }, service.quantity ? service.quantity : 1])
            }
          })
        }

        if (this.$store.state.order.order.streamingServices) {
          this.$store.state.order.order.streamingServices.options.forEach((service) => {
            if (service.selected) {
              tv.items.push([{ title: service.name }, service.quantity ? service.quantity : 1])
            }
          })
        }

        if (this.$store.state.order.order.additionalChannels) {
          this.$store.state.order.order.additionalChannels.options.forEach((service) => {
            if (service.selected) {
              tv.items.push([{ title: service.name }, service.quantity ? service.quantity : 1])
            }
          })
        }

        if (this.$store.state.order.order.additionalEquipment) {
          this.$store.state.order.order.additionalEquipment.options.forEach((service) => {
            if (service.selected) {
              tv.items.push([{ title: service.name }, service.quantity ? service.quantity : 1])
            }
          })
        }

        return tv
      },
      voiceServices() {
        const voice = {
          title: 'Voice',
          items: [],
        }

        if (this.$store.state.order.order.voice) {
          this.$store.state.order.order.voice.options.forEach((service) => {
            if (service.selected) {
              voice.items.push([{ title: service.name }, service.quantity ? service.quantity : 1])
            }
          })
        }

        return voice
      },
      installationServices() {
        const installation = {
          title: 'Installation',
          items: [[{ title: 'Self Install Kit' }, 1]],
        }

        return installation
      },
      showDiscountBanner() {
        // todo: check if the user has both autopay and ecosave enabled for their account
        // if both are true, we should not show the banner here
        return true
      },
    },
    mounted() {},
    methods: {
      enableAutoPayClicked() {
        this.trackEvent('')
      },
      printReceipt() {
        window.print()
      },
    },
  }
</script>

<style scoped>
  .pool {
    @apply rounded-md bg-gray-light p-4;
  }
</style>
