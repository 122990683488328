<template>
  <main class="space-y-6 pb-32 md:space-y-12 md:pb-36">
    <BzlTitlePair centered>
      <template #title> Hardware Requirement </template>
      <template #subtitle>
        Upgrade hardware to provide the best performance for your speed.
      </template>
    </BzlTitlePair>

    <div class="grid grid-cols-12 gap-4">
      <AppPanel class="col-span-full lg:col-span-8">
        <div class="text-lg font-bold">
          <template v-if="requiresModemUpgrade">
            A hardware upgrade is required for your new speed.
          </template>
          <template v-else> Do you need to lease a high-speed gateway? </template>
        </div>
        <AppPanel variant="alternate">
          <div class="space-y-6">
            <div class="flex flex-row items-start justify-between">
              <div>
                <div class="text-xl font-bold">{{ gatewayLease.title }}</div>
                <div class="text-sm">{{ gatewayLease.subtitle }}</div>
              </div>
              <div>
                <BzlPriceDisplay :price="gatewayLease.monthlyPrice"></BzlPriceDisplay>
              </div>
            </div>
            <div class="grid grid-cols-4 gap-6">
              <div class="col-span-full lg:col-span-3">
                A Gateway contains a High-Speed Internet Modem. 24/7 Remote Support Enabled.
              </div>
              <div class="lg:place-self-end">
                <BzlButton
                  v-if="getFromCart(gatewayLease)"
                  variant="success"
                  @click="removeAddOn(gatewayLease)">
                  Selected
                </BzlButton>
                <BzlButton
                  v-else
                  @click="addAddOn(gatewayLease)">
                  Select
                </BzlButton>
              </div>
            </div>
          </div>
          <hr />
          <div class="space-y-6">
            <div class="flex flex-row items-start justify-between">
              <div>
                <div class="text-xl font-bold">WiFi Your Way™</div>
                <div class="text-sm">WiFi Coverage Extension</div>
              </div>
              <div v-if="wifiYourWay">
                <BzlPriceDisplay :price="wifiYourWay.monthlyPrice"></BzlPriceDisplay>
              </div>
            </div>
            <div class="grid grid-cols-4 gap-6">
              <div class="col-span-full lg:col-span-1">
                <AppVideoModal
                  video-id="yQr9orxut44"
                  thumbnail-src="thumb-wifi-your-way.jpeg">
                  <template #label="slotProps">
                    <a
                      class="block text-center text-sm leading-tight"
                      @click.prevent="slotProps.toggle">
                      Learn how WiFi Your Way™ Home works!
                    </a>
                  </template>
                </AppVideoModal>
              </div>
              <div class="col-span-full lg:col-span-2">
                WiFi Your Way™ Home <strong>extends WiFi coverage</strong> to every inch of your
                home, and provides better security, parental controls, and a dedicated App.
                Everything needed for a reliably fast, whole home WiFi network.
              </div>

              <div
                v-if="wifiYourWay"
                class="col-span-full place-self-end lg:col-span-1">
                <BzlButton
                  v-if="getFromCart(wifiYourWay, 'wifi')"
                  variant="success"
                  @click="removeWifi(wifiYourWay)">
                  Upgraded
                </BzlButton>
                <BzlButton
                  v-else-if="getFromCart(gatewayLease)"
                  @click="addWifi(wifiYourWay)">
                  Upgrade
                </BzlButton>
                <BzlButton
                  v-else
                  disabled>
                  Requires Gateway
                </BzlButton>
              </div>
            </div>
          </div>

          <AppPanel
            v-if="wifiIsSelected"
            class="mt-4">
            <div class="flex flex-col items-start justify-between lg:flex-row">
              <div class="font-bold leading-tight">Add even more WiFi Pods</div>
              <div>
                <BzlPriceDisplay
                  :price="additionalPods.monthlyPrice"
                  variant="mini"
                  unit="each"></BzlPriceDisplay>
              </div>
            </div>
            <div class="grid grid-cols-4 gap-6">
              <div class="col-span-full lg:col-span-3">
                Homes larger than 2400 sq/ft should consider adding additional WiFi Pods to cover
                their entire home.
              </div>
              <div class="lg:place-self-end">
                <BzlDropdown
                  placement="bottom"
                  @sl-select="setAdditionalPods">
                  <template #trigger>
                    <BzlButton
                      v-if="getFromCart(additionalPods)"
                      slot="trigger"
                      variant="success"
                      caret>
                      {{ additionalPodsCount }}
                    </BzlButton>
                    <BzlButton
                      v-else
                      slot="trigger"
                      caret>
                      Add
                    </BzlButton>
                  </template>
                  <BzlMenu>
                    <BzlMenuItem value="0">0</BzlMenuItem>
                    <BzlMenuItem
                      v-for="i in additionalPods.maxQty"
                      :key="i"
                      :value="i">
                      {{ i }}
                    </BzlMenuItem>
                  </BzlMenu>
                </BzlDropdown>
              </div>
            </div>
          </AppPanel>
        </AppPanel>
        <AppPanel variant="alternate">
          <div>
            <div class="text-xl font-bold">
              <template v-if="requiresModemUpgrade">
                I will provide my own compatible equipment
              </template>
              <template v-else> I have my own equipment </template>
            </div>
            <div class="text-sm">
              You can use your own modem but you will not get the following features:
            </div>
          </div>
          <div class="grid grid-cols-4 gap-6">
            <div class="col-span-full lg:col-span-3">
              <ul class="list-inside list-disc space-y-2">
                <li>No 24/7 support for your modem and WiFi service.</li>
                <li>No integrated Parental Controls and device security.</li>
                <li>No home phone service available (requires a Breezeline-provided modem).</li>
                <li>
                  Your modem might not be compatible, please check our
                  <a
                    href="https://www.breezeline.com/assets/documents/2021_approved_modems.pdf"
                    target="_blank"
                    class="underline">
                    compatibility list
                  </a>
                  .
                </li>
              </ul>
            </div>
            <div class="lg:place-self-end">
              <BzlButton
                v-if="getFromCart(customerOwnedModem)"
                variant="success"
                @click="removeAddOn(customerOwnedModem)">
                Selected
              </BzlButton>
              <BzlButton
                v-else
                @click="addAddOn(customerOwnedModem)">
                Select
              </BzlButton>
            </div>
          </div>
        </AppPanel>
      </AppPanel>
      <AppPanel class="col-span-full lg:col-span-4">
        <AppCart
          title="Updated Service"
          :cart-data="$store.state.cart"
          :hardware-editable="false"></AppCart>
      </AppPanel>
    </div>

    <AppWizardNav color="dark">
      <a
        target="_self"
        class="flex items-center gap-2 px-4 text-white hover:underline"
        @click="$router.push({ name: 'change-your-speed' })">
        <BzlIcon name="arrow-left"></BzlIcon>
        <span class="hidden sm:inline">Change Your Speed</span>
        <span class="sm:hidden">Back</span>
      </a>
      <BzlButton
        variant="default"
        size="large"
        :disabled="isNextDisabled"
        @click="onNextPage">
        Continue
        <BzlIcon name="arrow-right"></BzlIcon>
      </BzlButton>
    </AppWizardNav>
  </main>
</template>

<script>
  export default {
    name: 'HardwareRequirementPage',
    computed: {
      requiresModemUpgrade() {
        return !this.$store.state.cart.services.internet.modems.includes(
          this.$store.state.account.modem
        )
      },
      isNextDisabled() {
        return !(this.getFromCart(this.gatewayLease) || this.getFromCart(this.customerOwnedModem))
      },
      getFromCart() {
        return (addOn, type) => {
          if (!type) {
            type = 'internet'
          }

          return this.$store.getters['cart/getAddOnFromCart']({ type, addOn })
        }
      },
      gatewayLease() {
        return this.$store.state.equipment.gatewayLease
      },
      customerOwnedModem() {
        return this.$store.state.equipment.customerOwnedModem
      },
      wifiYourWay() {
        return this.$store.state.equipment.wifiYourWay
      },
      additionalPods() {
        return this.$store.state.equipment.additionalPods
      },
      additionalPodsCount() {
        const pods = this.getFromCart(this.additionalPods, 'internet')
        if (pods) {
          return pods[1]
        }

        return 0
      },
      wifiIsSelected() {
        return this.$store.state.cart.addOns.wifi.length
      },
    },
    async mounted() {
      if (!this.requiresModemUpgrade) {
        this.$router.push({ name: 'change-your-speed' })
      }
    },
    methods: {
      async addAddOn(addOn) {
        try {
          this.$store.dispatch('cart/addAddOn', { type: 'internet', addOn })
          const wifi = this.$store.state.equipment.wifiYourWay
          const superpods = this.$store.state.equipment.additionalPods
          const selfInstallKit = this.$store.state.equipment.selfInstallationKit

          // update the equipment selection on the offer
          if (
            addOn.id === this.$store.state.equipment.gatewayLease.id ||
            addOn.id === this.$store.state.equipment.customerOwnedModem.id
          ) {
            this.$store.dispatch('equipment/selectEquipment')
          }
          // if we're adding a customer owned modem, remove any self install kit
          // the cart
          if (addOn.id === this.$store.state.equipment.customerOwnedModem.id) {
            this.$store.dispatch('cart/removeAddOn', {
              type: 'installation',
              addOn: selfInstallKit,
            })
            this.$store.dispatch('cart/removeAddOn', { type: 'wifi', addOn: wifi })
            this.$store.dispatch('cart/removeAddOn', { type: 'internet', addOn: superpods })
            await this.$store.dispatch('equipment/removeWifiAndSuperPods')
          }

          // if we're adding a gateway lease, add a self install kit to the cart
          if (addOn.id === this.$store.state.equipment.gatewayLease.id) {
            this.$store.dispatch('cart/addAddOn', {
              type: 'installation',
              addOn: this.$store.state.equipment.selfInstallationKit,
            })
          }

          if (addOn.id === this.$store.state.equipment.wifiYourWay.id) {
            await this.$store.dispatch('equipment/addWiFiYourWay')
          }
        } catch (error) {
          this.$store.dispatch('showError', error)
        }
      },
      removeAddOn(addOn) {
        return this.$store.dispatch('cart/removeAddOn', { type: 'internet', addOn })
      },
      async addWifi(addOn) {
        await this.$store.dispatch('cart/addAddOn', { type: 'wifi', addOn })
      },
      async removeWifi(addOn) {
        await this.$store.dispatch('cart/removeAddOn', { type: 'wifi', addOn })
        return await this.$store.dispatch('equipment/removeWifiAndSuperPods')
      },
      async setAdditionalPods(e) {
        const quantity = Number(e.detail.item.value)
        const originalQuantity = this.$store.getters['order/superpodsQuantity']
        const pods = this.additionalPods

        // if the quantity selected doesn't match the original quantity
        // selected, don't show the item in the cart summary
        if (quantity !== originalQuantity) {
          pods.isVisible = true
        } else {
          pods.isVisible = false
        }

        if (quantity > 0) {
          await this.$store.dispatch('cart/addAddOn', {
            type: 'internet',
            addOn: pods,
            quantity,
          })
        } else {
          await this.$store.dispatch('cart/removeAddOn', {
            type: 'internet',
            addOn: pods,
          })

          return await this.$store.dispatch('equipment/addAdditionalSuperPods')
        }
      },
      async onNextPage() {
        this.$store.dispatch('lockApp')

        try {
          // If a modem upgrade is required, we need to add a self install kit,
          // otherwise make sure it hasn't been added
          if (
            this.$store.state.internet.requiresModemUpgrade &&
            this.getFromCart(this.$store.state.equipment.selfInstallationKit, 'installation')
          ) {
            await this.$store.dispatch('products/addSelfInstallKit', null, { root: true })
          } else {
            await this.$store.dispatch('products/removeSelfInstallKit', null, { root: true })
          }

          await this.$store.dispatch('equipment/submit')

          // track hardware selection
          const event = window.gtag?.events.OS_CONTINUE_HARDWARE_SELECTIONS
          if (event) {
            event[2] = 'gateway'

            if (this.$store.state.cart.addOns.wifi.length > 0) {
              event[2] = 'gateway and wyw'
            }

            if (
              this.$store.state.cart.addOns.internet.length > 0 &&
              this.$store.state.cart.addOns.internet[0][0]?.id === 'customer-owned-modem'
            ) {
              event[2] = 'own modem'
            }

            this.trackEvent(event)
          }

          this.$router.push({ name: 'cart' })
        } catch (error) {
          if (error.cause === 'conversationId') {
            return this.$store.dispatch('restartUpgrade')
          }

          this.$store.dispatch('showError', error)
          this.$router.push({ name: 'manual-upgrade' })
        }

        this.$store.dispatch('unlockApp')
      },
    },
  }
</script>
