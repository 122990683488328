import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import SentryRRWeb from '@sentry/rrweb'

if (import.meta.env.VITE_SENTRY_DSN === '') {
  console.log('No Sentry DSN is set. Will not be reporting to Sentry')
}

Sentry.init({
  Vue,
  environment: import.meta.env.MODE,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  sampleRate: parseFloat(import.meta.env.VITE_SENTRY_SAMPLE_RATE) || 0.01,
  integrations: [
    new SentryRRWeb(), // session replay
  ],
  release: __RELEASE__,
  allowUrls: [/https?:\/\/([\w-]+\.)*breezeline\.com/],
})

window.Sentry = Sentry
