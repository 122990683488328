export default {
  namespaced: true,

  state: {
    services: {
      internet: null, // InternetService only
      // tv: null,
      // voice: null,
    },
    addOns: {
      internet: [], // array of [InternetAddOn{}, count<integer>], only 1 hardware category allowed
      wifi: [],
      // tv: [],
      // voice: [],
      installation: [],
    },
    localTax: 0, // from API
  },

  getters: {
    getAddOnFromCart:
      (state) =>
      ({ type, addOn }) => {
        return state.addOns[type].find((i) => i[0].id === addOn.id)
      },
  },

  mutations: {
    SET_SERVICE(state, { type, service }) {
      state.services[type] = service
      // note: after adding an internet service, we can now know if they need a modem upgrade or not
    },
    ADD_ADD_ON(state, { type, addOn, quantity = 1 }) {
      if (type === 'installation') {
        state.addOns.installation = []
      }

      // if adding a (new/different) hardware, always start fresh with an empty addOns array for that type
      if (addOn.category === 'gateway-lease') {
        state.addOns[type] = []
      }

      // if adding a (new/different) hardware, always start fresh with an empty addOns array for that type
      if (addOn.category === 'customer-owned-modem') {
        // if we're adding a customer owned modem. clear out everything
        state.addOns.installation = []
        state.addOns.wifi = []
        state.addOns.internet = []
      }

      if (type === 'wifi') {
        state.addOns.wifi = []
      }

      // for features, remove it first, then re-add it instead of modifying quantity (to keep reactivity)
      if (addOn.category === 'feature') {
        state.addOns[type] = state.addOns[type].filter((i) => i[0] !== addOn)
      }

      // for "superpods", remove it first, then re-add it instead of modifying quantity (to keep reactivity)
      if (addOn.category === 'superpods') {
        state.addOns[type] = state.addOns[type].filter((i) => i[0] !== addOn)
      }

      state.addOns[type].push([addOn, quantity])
    },
    REMOVE_ADD_ON(state, { type, addOn }) {
      // these are categories that if they are removed, the addOns in the type
      // should also be removed.
      const removeAllFromTheseTypes = ['customer-owned-modem', 'gateway-lease', 'wifi']

      if (removeAllFromTheseTypes.includes(addOn.category)) {
        state.addOns[type] = []
      } else {
        state.addOns[type] = state.addOns[type].filter((add) => {
          return add[0].id !== addOn.id
        })
      }

      // loop through all of the addOn arrays and if an add on in the cart is
      // found to have a dependency of the add on we're removing's category,
      // remove it as well
      for (const key in state.addOns) {
        state.addOns[key] = state.addOns[key].filter((add) => {
          return !add[0]?.dependencies.includes(addOn.category)
        })
      }
    },
    SET_LOCAL_TAX(state, tax) {
      state.localTax = tax
    },
  },

  actions: {
    setService({ commit, dispatch }, payload) {
      commit('SET_SERVICE', payload)

      if (payload.type === 'internet') {
        this.dispatch('internet/determineIfModemUpgradeIsRequired', payload.service, { root: true })
      }
    },
    async addAddOn({ commit }, payload) {
      commit('ADD_ADD_ON', payload)
    },
    async removeAddOn({ commit }, payload) {
      commit('REMOVE_ADD_ON', payload)
    },
  },
}
