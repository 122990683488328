import CookieAccessErrorPage from './pages/CookieAccessErrorPage.vue'
import ServerErrorPage from './pages/ServerErrorPage.vue'
import MaintenancePage from './pages/MaintenancePage.vue'
import LoadingPage from './pages/LoadingPage.vue'

export default [
  {
    name: 'cookie-access-error',
    path: '/400',
    meta: { isAux: true },
    components: {
      main: CookieAccessErrorPage,
    },
    beforeEnter,
  },
  {
    name: 'server-error',
    path: '/500',
    meta: { isAux: true },
    components: {
      main: ServerErrorPage,
    },
    beforeEnter,
  },
  {
    name: 'maintenance',
    path: '/maintenance',
    meta: { isAux: true },
    components: {
      main: MaintenancePage,
    },
    beforeEnter,
  },
  {
    name: 'loading',
    path: '/loading',
    meta: { isAux: true },
    components: {
      main: LoadingPage,
    },
    beforeEnter,
  },
]

function beforeEnter(to, from, next) {
  setTimeout(() => {
    window.location = window.location.origin
  }, 1000 * 60)

  next()
}
