import SpeedData from '*/Speeds.js'
import { InternetService } from '$/InternetService'
import { InternetAddOn } from '$/InternetAddOn'

const DEVICE_NUMBER_MAPPING = {
  100: '1-3',
  200: '4-7',
  500: '8-12',
  1000: '12+',
}

const SPEED_CREDIT_MAPPING = [
  {
    regex: /\sFast/,
    discount: 5,
  },
  {
    regex: /Ultra/,
    discount: 10,
  },
  {
    regex: /Giga/,
    discount: 10,
  },
]

// Prepare the Internet Service models.
export const prepareInternetServices = function (options, credits) {
  const services = {
    currentServiceId: null,
    allServices: [],
  }

  options.forEach((option) => {
    if (option.selected === true) {
      services.currentServiceId = option.id
    }

    const service = SpeedData[option.id]

    service.title = option.name
    service.monthlyPrice = option.monthlyPrice
    service.preCreditPrice = option.monthlyPrice

    // If credits are passed, they're available in the market so subtract em
    // from the monthly price
    if (credits) {
      SPEED_CREDIT_MAPPING.forEach((mapping) => {
        if (service.title.match(mapping.regex)) {
          service.monthlyPrice = service.monthlyPrice - mapping.discount
        }
      })
    }

    services.allServices.push(new InternetService(service))
  })

  return services
}

export const prepareSuperPodAddOn = function (addOn) {
  return new InternetAddOn({
    id: addOn.id,
    title: addOn.name,
    monthlyPrice: addOn.monthlyPrice,
    titleInCart: `Add'l WiFi Pods`,
    minQty: 0,
    maxQty: 5,
    dependencies: ['wifi'],
    category: 'feature',
    isVisible: addOn.isVisible,
  })
}

export const prepareWifiAddOn = function (addOn) {
  return new InternetAddOn({
    id: addOn.id,
    category: 'wifi',
    title: addOn.name,
    monthlyPrice: addOn.monthlyPrice,
    titleInCart: 'WiFi Your Way™ Home',
    minQty: 0,
    maxQty: 0,
    subtitle: 'N/A',
    dependencies: ['gateway-lease'],
    isVisible: addOn.isVisible,
  })
}

export const prepareEquipmentAddOn = function (addOn) {
  return new InternetAddOn({
    id: addOn.id,
    title: addOn.name,
    monthlyPrice: addOn.monthlyPrice,
    titleInCart: `Add'l WiFi Pods`,
    minQty: 0,
    maxQty: 5,
    dependencies: ['wifi'],
    category: 'feature',
    isVisible: addOn.isVisible,
  })
}

export default {
  needle() {
    return this.speed / 10
  },
  speedTitle() {
    return `${this.speed} Mbps`
  },
  idealFor() {
    const speeds = Object.keys(DEVICE_NUMBER_MAPPING)
    let matched = null

    speeds.forEach((mapping, i) => {
      if (this.speed >= speeds[i] && this.speed <= (speeds[i + 1] ? speeds[i + 1] : speeds[i])) {
        matched = DEVICE_NUMBER_MAPPING[mapping]
      }
    })

    return `Ideal for ${matched} devices`
  },
  prepareInternetServices,
  prepareSuperPodAddOn,
  prepareWifiAddOn,
}
