import { DateTime } from 'luxon'

function isUnderMaintenance(toMaintenancePage) {
  let status = false

  const {
    VITE_MAINTENANCE_FORCE: force,
    VITE_MAINTENANCE_START: start,
    VITE_MAINTENANCE_END: end,
    VITE_MAINTENANCE_SERVER_TZ: timeZone,
    VITE_MAINTENANCE_POLL_RATE: pollRate,
  } = import.meta.env

  if (force === 'true' || force === '1') {
    status = true
  } else {
    // Convert browser time to server TZ to allow for comparison against start/end
    const now = DateTime.local().setZone(timeZone)
    status = start ? now > DateTime.fromFormat(start, "yyyy-MM-dd'T'HH:mm:ssZZ") : status
    status = end ? now < DateTime.fromFormat(end, "yyyy-MM-dd'T'HH:mm:ssZZ") : status
  }

  if (status) {
    toMaintenancePage()
  } else {
    // if not currently in maintenance, start polling
    setTimeout(() => {
      isUnderMaintenance(toMaintenancePage)
    }, 1000 * (Number.parseInt(pollRate) || 15))
  }

  return status
}

export { isUnderMaintenance }
