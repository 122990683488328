<template>
  <sl-menu-item
    v-bind="{ ...$props, ...$attrs }"
    class="BzlMenuItem"
    v-on="$listeners">
    <slot></slot>
  </sl-menu-item>
</template>

<script>
  import '@shoelace-style/shoelace/dist/components/menu-item/menu-item.js'
  export default {
    name: 'BzlMenuItem',
    // props: {} // todo
  }
</script>
