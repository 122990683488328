import axios from 'axios'
export default {
  namespaced: true,
  state: () => ({
    labelData: [],
    labelsAreloading: true,
    planId: null,
  }),
  getters: {
    getProductLabelData: (state) => (offerName) => {
      const matchedData = state.labelData
        .flat()
        .find((data) => data?.labels?.some((label) => label.name === offerName))
      return matchedData ?? false
    },
  },
  mutations: {
    STORE_LABEL(state, payload) {
      state.labelData = [...state.labelData, payload]
    },
    RESET_PLANID(state) {
      state.planId = []
    },
    CHANGE_LOADING_STATE(state, loading) {
      state.labelsAreloading = loading
    },
    SET_PLAN_ID(state, planId) {
      state.planId = null
      state.planId = planId
    },
  },
  actions: {
    setLoadingState({ commit }, payload) {
      commit('CHANGE_LOADING_STATE', payload)
    },
    async fetchBroadbandLabel({ commit }, payload) {
      commit('CHANGE_LOADING_STATE', true)
      console.log('fetching labels...')
      await axios
        .post('/api/actifai/labels', {
          ...payload,
        })
        .then(function (response) {
          // handle success
          console.log(response)
          commit('STORE_LABEL', response.data)
          commit('CHANGE_LOADING_STATE', false)
        })
        .catch(function (error) {
          // handle error
          console.log(error)
          console.log('there was an error')
          commit('CHANGE_LOADING_STATE', false)
        })
    },
  },
}
