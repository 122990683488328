<template>
  <div id="app">
    <template v-if="$route.meta.isAux">
      <RouterView name="main"></RouterView>
    </template>
    <template v-else>
      <template v-if="$store.state.appIsReady">
        <div
          class="bg-white shadow"
          role="navigation"
        >
          <div class="container mx-auto flex flex-row items-end justify-between py-4">
            <a href="https://www.breezeline.com">
              <img
                src="/logo.svg"
                class="max-h-8 w-36 lg:max-h-10"
                alt="Logo"
              />
            </a>
            <a
              class="text-lg font-heavy text-navy-dark"
              :href="$store.getters['myAccountUrl']"
              >My Account</a
            >
            {{ ujetIsReadyToDisplay }}
          </div>
          <div class="hidden bg-navy-dark py-4 text-sm text-white lg:block">
            <div class="container mx-auto flex flex-row items-center justify-between">
              <div>
                <div
                  v-if="loginName"
                  class="text-lg font-bold"
                >
                  Welcome, {{ loginName }}!
                </div>
                <div v-if="accountNumber">Account #: {{ accountNumber }}</div>
              </div>
              <div class="flex flex-col items-end">
                <div class="font-bold">
                  {{ email }}
                </div>
                <a
                  href="#"
                  class="underline"
                  @click="$store.dispatch('account/logout')"
                >
                  Logout
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="container mx-auto mt-6 lg:mt-0">
          <AppBreadcrumbs
            class="py-12"
            :breadcrumbs="breadcrumbs"
            :current-route-name="$route.name"
          ></AppBreadcrumbs>
          <RouterView
            name="main"
            @on-modem-upgrade-disclaimer-click="modals.modemUpgradeDisclaimer = true"
            @on-internet-speed-disclaimer-click="modals.internetSpeedDisclaimer = true"
          ></RouterView>
        </div>
      </template>

      <BzlAlert :alert="$store.state.alert"></BzlAlert>

      <BzlLoadingOverlay v-if="$store.state.appIsProcessing"></BzlLoadingOverlay>

      <BzlModal
        :open="modals.internetSpeedDisclaimer"
        label="Internet Speed Disclaimer"
        @sl-after-hide="modals.internetSpeedDisclaimer = false"
      >
        <div class="space-y-4">
          <p>
            Speeds are stated for download only over wired connections. Wireless speeds will be
            substantially lower. Actual Internet speeds, including wireless speeds, may vary due to
            conditions outside of network control and congestion, including customer location,
            equipment, and access through a wireless connection, and are not guaranteed; please see
            Breezeline’s Network Management Disclosure on the
            <a
              href="https://www.breezeline.com/policies-agreements"
              target="_blank"
            >
              Policies & Agreements
            </a>
            page for details. Actual speeds for Unlimited Gigafast Internet Service may vary due to
            subscriber device limitations.
          </p>
        </div>
      </BzlModal>
      <BzlModal
        :open="modals.modemUpgradeDisclaimer"
        label="Modem Upgrade Disclaimer"
        @sl-after-hide="modals.modemUpgradeDisclaimer = false"
      >
        <div class="space-y-4">
          <p>
            To provide the best performance for this faster internet speed you will need to upgrade
            your modem.
          </p>
          <p>
            If you select this option, you will be prompted to lease a new Breezeline Gateway, or
            provide your own compatible modem and router.
          </p>
        </div>
      </BzlModal>
    </template>
  </div>
</template>

<script>
  import { createUjetObj } from './ccai/ccai.js'
  export default {
    name: 'OrderGateway',
    components: {},
    data: () => ({
      modals: {
        internetSpeedDisclaimer: false,
        modemUpgradeDisclaimer: false,
      },
    }),
    computed: {
      account() {
        return this.$store.state.account
      },
      accountNumber() {
        return this.account.accountNumber
      },
      loginName() {
        return `${this.account.customerInfo.firstName} ${this.account.customerInfo.lastName}`
      },
      email() {
        return this.$root.user.email
      },
      // eslint-disable-next-line vue/return-in-computed-property
      ujetIsReadyToDisplay() {
        const ujetData = {
          state: this.account?.address?.state ? this.account?.address?.state : 'Breezeline',
          email: this.$root.user.email,
          name: `${this.account.customerInfo.firstName} ${this.account.customerInfo.lastName}`,
        }
        if (ujetData.state && ujetData.email && !ujetData.name.includes('null'))
          createUjetObj(ujetData)
      },
      breadcrumbs() {
        if (this.requiresModemUpgrade) {
          return [
            {
              label: 'Change Your Speed',
              routeName: 'change-your-speed',
            },
            {
              label: 'Hardware Requirement',
              routeName: 'hardware-requirement',
            },
            {
              label: 'Cart',
              routeName: 'cart',
            },
            {
              label: 'Confirmation',
              routeName: 'confirmation',
            },
          ]
        }

        return [
          {
            label: 'Change Your Speed',
            routeName: 'change-your-speed',
          },
          {
            label: 'Cart',
            routeName: 'cart',
          },
          {
            label: 'Confirmation',
            routeName: 'confirmation',
          },
        ]
      },
      requiresModemUpgrade() {
        return !this.$store.state.cart.services.internet?.modems.includes(
          this.$store.state.account.modem
        )
      },
    },
    created() {
      window.$apollo = this.$apollo
      // this.$store.commit('LOGIN', this.$root.user)
    },
    async mounted() {
      console.info(`v${__RELEASE__}`)

      this.$store.dispatch('lockApp')

      try {
        // get the cart early to catch an invalid conversation id upon page load
        await this.$store.dispatch('initializeAll')
        await this.$store.dispatch('order/getCart', { silent: true })

        // get the cart every 10 minutes so we can keep the conversation id fresh
        window.setInterval(
          async () => {
            try {
              await this.$store.dispatch('order/getCart', { silent: true })
            } catch (error) {
              this.$store.dispatch('handleError', error)
            }
          },
          1000 * 60 * 10 // 10 minutes
        )

        // push the uuid to hotjar
        if (window.hj && this.$store.state.account.uuid) {
          window.hj('identify', this.$store.state.account.uuid, {})
        }
      } catch (error) {
        this.$store.dispatch('handleError', error)
      }

      this.$store.dispatch('unlockApp')
      this.$store.dispatch('showApp')
    },
    methods: {
      routeTo(name, replace = false) {
        if (name !== this.$route.name) {
          this.$router.push({ name, replace })
        }
      },
    },
  }
</script>

<style>
  .container {
    @apply px-4 lg:px-8;
  }
</style>
