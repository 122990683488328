<template>
  <div class="flex flex-row items-center gap-6">
    <div
      class="relative w-24"
      style="height: 3rem">
      <img
        class="absolute"
        alt=""
        src="./gauge-background.svg" />

      <img
        class="absolute -bottom-2 left-2 transform-gpu"
        alt=""
        :style="needleRotation"
        style="transform-origin: 59px 7px; bottom: -0.5rem; left: -0.75rem; width: 4rem"
        src="./gauge-needle.svg" />
    </div>
    <div class="flex flex-col leading-tight text-navy-dark">
      <div
        v-if="replacementFor"
        class="text-sm font-bold line-through opacity-50 grayscale filter">
        {{ replacementFor }}
      </div>
      <BzlTitlePair>
        <template #title>
          {{ title }}
          <BzlIconButton
            v-if="showDisclaimer"
            class="text-sm"
            name="info-circle-fill"
            label="More Information"
            @click="(e) => $emit('on-disclaimer-click', e)"></BzlIconButton>
        </template>
        <template #subtitle>{{ subtitle }}</template>
      </BzlTitlePair>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'BzlInternetSpeedGauge',
    props: {
      speed: {
        // could be upload or download
        type: Number,
        required: false,
        default: 0,
      },
      title: {
        type: String,
        required: false,
        default: 'XXXX Mbps',
      },
      subtitle: {
        type: String,
        required: false,
        default: 'Ideal for: X-Y devices',
      },
      replacementFor: {
        type: String,
        required: false,
        default: null,
      },
      showDisclaimer: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    computed: {
      needleRotation() {
        // note: needle rotation is based on speed prop

        // Option 1: we map a speed to a specific degree of rotation
        // const deg = {
        //   0: 0,
        //   50: 25,
        //   100: 30,
        //   150: 40,
        //   200: 50,
        //   400: 60,
        //   500: 75,
        //   1000: 95,
        // }[this.speed]

        // Option 2: we calculate the rotation based off the max speed available
        const maxSpeed = 1000 // Mbps
        const deg = (this.speed / maxSpeed) * 100

        return `transform: rotate(${(deg * 180) / 100}deg)`
      },
    },
  }
</script>

<style scoped>
  .BzlIconButton::part(base) {
    @apply text-blue-light;
  }
</style>
