import axios from 'axios'
import endpoints from './endpoints.js'
import { InternetAddOn } from '../../../models/InternetAddOn.js'

export default {
  namespaced: true,
  state: {
    selectedEquipment: null,
    // whether or not we should automatically add WiFi Your Way to the cart
    autoAddWiFi: true,
    gatewayLease: {
      id: 'gateway-lease',
      category: 'gateway-lease',
      title: 'High-Speed Gateway', // or 'Fiber Gateway'
      titleInCart: 'High-Speed Gateway Lease', // or 'Fiber Gateway Lease'
      subtitle: 'Internet Gateway Lease',
      monthlyPrice: 14.99,
      isVisible: true,
    },
    customerOwnedModem: {
      id: 'customer-owned-modem',
      category: 'customer-owned-modem',
      title: 'Customer Owned Modem',
      titleInCart: 'Customer Owned Modem',
      monthlyPrice: 0,
      isVisible: true,
    },
    wifiYourWay: {
      id: 'wifi-your-way',
      category: 'feature',
      title: 'WiFi Your Way',
      titleInCart: 'WiFi Your Way™ Home',
      monthlyPrice: 0,
      isVisible: true,
    },
    wifiYourWayOptions: [],
    additionalPods: {
      id: 'additional-pods',
      category: 'superpods',
      title: 'Additional Pods',
      titleInCart: `Add'l WiFi Pods`,
      monthlyPrice: 4.99, // each
      minQty: 0,
      maxQty: 5,
      dependencies: ['wifi-your-way'],
      isVisible: true,
    },
    selfInstallationKit: {
      id: 'self-install-kit',
      title: 'Self Installation',
      titleInCart: 'Self Install Kit',
      monthlyPrice: 0,
      isEditable: false,
      isVisible: true,
      category: 'installation',
      dependencies: ['gateway-lease'],
    },
    changeOfServiceNoTruck: {},
    professionalInstallation: {
      id: 'professional-installation',
      title: 'Professional Install',
      titleInCart: 'Professional Install',
      monthlyPrice: 0,
      isVisible: true,
    },
  },

  getters: {
    originalSelections(state, getters, rootState, rootGetters) {
      return rootGetters['order/originalSelections']
    },
    superpodsQuantity(state, getters, rootState, rootGetters) {
      return rootGetters['order/superpodsQuantity']
    },
  },

  mutations: {
    SET_OPTIONS(state, equipment) {
      state.options = equipment
    },
    SET_GATEWAY_LEASE(state, option) {
      state.gatewayLease = option
    },
    SET_CUSTOMER_OWNED_MODEM(state, option) {
      state.customerOwnedModem = option
    },
    SET_WIFI_YOUR_WAY(state, option) {
      state.wifiYourWay = option
    },
    SET_ADDITIONAL_PODS(state, option) {
      state.additionalPods = option
    },
  },

  actions: {
    async updateWiFiYourWayOption({ state, commit, getters }, options) {
      // reset wifi your way
      commit('SET_WIFI_YOUR_WAY', null)

      // we only want to show the wifi your way option in the cart if it is a
      // new addition to the account
      const isVisible = !getters.originalSelections.wifi

      // some offers have two wifi your way options. one with an single included
      // pod, another with two included pods. we only want to offer the option
      // with a single pod but some users already have the one with two pods
      // selected.

      // first loop through the options and see if the user has wifi your way
      // selected.
      options.forEach((option) => {
        if (option.selected) {
          option = new InternetAddOn({
            id: option.id,
            category: 'wifi',
            title: option.name,
            monthlyPrice: option.monthlyPrice,
            titleInCart: 'WiFi Your Way™ Home',
            minQty: 0,
            maxQty: 0,
            subtitle: 'N/A',
            dependencies: ['gateway-lease'],
            isVisible,
          })

          commit('SET_WIFI_YOUR_WAY', option)
        }
      })

      if (state.wifiYourWay) {
        return
      }

      // we don't have any options already selected so loop through them and
      // skip over the option that has two pods. we only want to offer the
      // option with one pod
      options.forEach((option) => {
        if (option.name.includes('2 Pods')) {
          return
        }

        option = new InternetAddOn({
          id: option.id,
          category: 'wifi',
          title: option.name,
          monthlyPrice: option.monthlyPrice,
          titleInCart: 'WiFi Your Way™ Home',
          minQty: 0,
          maxQty: 0,
          subtitle: 'N/A',
          dependencies: ['gateway-lease'],
          isVisible,
        })

        commit('SET_WIFI_YOUR_WAY', option)
      })
    },
    updateCustomerOwnedModemOption({ getters, commit }, options) {
      options.forEach((option) => {
        if (option.id === 'customer-owned-modem') {
          option = new InternetAddOn({
            id: option.id,
            title: option.name,
            category: 'customer-owned-modem',
            monthlyPrice: option.monthlyPrice,
            titleInCart: 'Customer Owned Modem',
            minQty: 0,
            maxQty: 0,
            subtitle: 'N/A',
            isVisible: getters.originalSelections.equipment !== option.name,
          })

          commit('SET_CUSTOMER_OWNED_MODEM', option)
        }
      })
    },
    updateGatewayLeaseOption({ getters, commit }, options) {
      options.forEach((option) => {
        if (option.id === 'gateway-lease') {
          option = new InternetAddOn({
            id: option.id,
            title: option.name,
            monthlyPrice: option.monthlyPrice,
            category: 'gateway-lease',
            titleInCart: 'High-Speed Gateway Lease', // or 'Fiber Gateway Lease'
            subtitle: 'Internet Gateway Lease',
            isVisible: getters.originalSelections.equipment !== option.name,
          })

          commit('SET_GATEWAY_LEASE', option)
        }
      })
    },
    updateAdditionalPodsOption({ getters, commit }, options) {
      options.forEach((option) => {
        option = new InternetAddOn({
          id: option.id,
          title: option.name,
          monthlyPrice: option.monthlyPrice,
          titleInCart: `Add'l WiFi Pods`,
          minQty: 0,
          maxQty: 5,
          dependencies: ['wifi'],
          category: 'feature',
          isVisible: !getters.originalSelections.superpods,
        })

        commit('SET_ADDITIONAL_PODS', option)
      })
    },
    async updateEquipmentFromOfferConfig({ state, commit, dispatch }, options) {
      // update equipment data from the api
      if (options) {
        await dispatch('equipment/updateGatewayLeaseOption', options, {
          root: true,
        })

        await dispatch('equipment/updateCustomerOwnedModemOption', options, {
          root: true,
        })
      }

      // not sure why I decided to update the cart from here. this should
      // probably be moved to the order or cart module
      options.forEach((option) => {
        if (option.id === 'gateway-lease' && option.selected) {
          dispatch(
            'cart/removeAddOn',
            {
              type: 'internet',
              addOn: state.customerOwnedModem,
            },
            { root: true }
          )

          dispatch(
            'cart/addAddOn',
            {
              type: 'internet',
              addOn: state.gatewayLease,
            },
            { root: true }
          )
        }

        if (option.id === 'customer-owned-modem' && option.selected) {
          dispatch(
            'cart/removeAddOn',
            {
              type: 'internet',
              addOn: state.gatewayLease,
            },
            { root: true }
          )

          dispatch(
            'cart/addAddOn',
            {
              type: 'internet',
              addOn: state.customerOwnedModem,
            },
            { root: true }
          )
        }
      })
    },
    async selectEquipment({ state, rootState, dispatch, commit }) {
      dispatch('lockApp', null, { root: true })

      let selected = null
      // loop through the addons in the cart and see if either the gateway
      // lease id or the customer owned modem is available
      rootState.cart.addOns.internet.forEach((addOn) => {
        if (addOn[0].id === state.gatewayLease.id) {
          selected = addOn[0].title
        }

        if (addOn[0].id === state.customerOwnedModem.id) {
          selected = addOn[0].title
        }
      })

      if (!selected) {
        dispatch('unlockApp', null, { root: true })
        return
      }

      const data = {
        uuid: rootState.account.uuid,
        conversationId: rootState.account.conversationId,
        selected,
      }

      return axios
        .post(endpoints.POST_EQUIPMENT, data)
        .then((response) => {
          // do we need to do anything on success?
        })
        .catch((error) => {
          throw new Error(error.response.data.message, { cause: 'api' })
        })
        .then(() => {
          dispatch('unlockApp', null, { root: true })
        })
    },
    async addWiFiYourWay({ state, rootState, dispatch }) {
      if (rootState.cart.addOns.wifi.length === 0) {
        return
      }

      dispatch('lockApp', null, { root: true })

      const data = {
        uuid: rootState.account.uuid,
        conversationId: rootState.account.conversationId,
        selected: rootState.cart.addOns.wifi[0][0].title,
      }

      return axios
        .post(endpoints.POST_WIFI, data)
        .then((response) => {
          // do we need to do anything on success?
        })
        .catch((error) => {
          throw new Error(error.response.data.message, { cause: 'api' })
        })
        .then(() => {
          dispatch('unlockApp', null, { root: true })
        })
    },
    async removeWifiAndSuperPods({ state, commit, rootState, dispatch }) {
      dispatch('lockApp', null, { root: true })

      const data = {
        uuid: rootState.account.uuid,
        conversationId: rootState.account.conversationId,
        selected: state.wifiYourWay.title, // first we're removing wifi your way
        remove: true,
      }

      await axios
        .post(endpoints.POST_WIFI, data)
        .then((response) => {
          commit('order/SET_ORDER', response.data.data, { root: true })
        })
        .catch((error) => {
          dispatch('unlockApp', null, { root: true })
          throw new Error(error.response.data.message, { cause: 'api' })
        })

      // remove super pods if necessary
      const superPodsInCart = rootState.cart.addOns.internet.find((addOn) => {
        return (addOn) => addOn[0].id === rootState.equipment.additionalPods.id
      })

      if (superPodsInCart) {
        data.selected = 0
        await axios
          .post(endpoints.POST_SUPERPODS, data)
          .then((response) => {
            commit('order/SET_ORDER', response.data.data, { root: true })
          })
          .catch((error) => {
            dispatch('unlockApp', null, { root: true })
            throw new Error(error.response.data.message, { cause: 'api' })
          })
      }

      dispatch('order/updateState', null, { root: true })
      dispatch('unlockApp', null, { root: true })
    },
    async addAdditionalSuperPods({ state, rootState, dispatch }) {
      dispatch('lockApp', null, { root: true })

      let quantity = 0

      rootState.cart.addOns.internet.forEach((addOn) => {
        if (addOn[0].id === state.additionalPods.id) {
          quantity = addOn[1]
        }
      })

      const data = {
        uuid: rootState.account.uuid,
        conversationId: rootState.account.conversationId,
        selected: quantity,
      }

      return axios
        .post(endpoints.POST_SUPERPODS, data)
        .then((response) => {
          dispatch('unlockApp', null, { root: true })
        })
        .catch((error) => {
          throw new Error(error.response.data.message, { cause: 'api' })
        })
    },
    async submit({ dispatch }) {
      await dispatch('selectEquipment')
      await dispatch('addWiFiYourWay')
      await dispatch('addAdditionalSuperPods')
    },
  },
}
