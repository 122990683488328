export default {
  namespaced: true,

  state: {
    current: {},
    available: [],
  },

  getters: {},

  mutations: {},

  actions: {},
}
