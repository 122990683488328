import { validate } from './validators.js'

export class Alert {
  constructor(o) {
    if (Alert.validate(o)) {
      this.variant = o.variant
      this.title = o.title
      this.subtitle = o.subtitle
      this.closable = o.closable || true
      this.duration = o.duration || 5000
    }
  }

  static validate(o) {
    return validate(o, {
      variant: [
        'isDefined',
        'isValidString',
        (val) => ['primary', 'success', 'neutral', 'warning', 'danger'].includes(val),
      ],
      title: ['isDefined', 'isValidString'],
      subtitle: ['isDefined', 'isValidString'],
      closable: [
        // default: true
        'isBoolean',
      ],
      duration: [
        // default: 5000ms
        'isPositiveNumber',
      ],
    })
  }
}
