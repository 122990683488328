<template>
  <BzlAlert
    :open="open"
    :closable="true"
    :variant="currentAlert?.variant"
    @sl-hide="close()">
    <div class="flex flex-col justify-between lg:flex-row">
      <div
        style="max-width: 1053px"
        class="leading-loose">
        <div class="text-sm font-bold leading-loose">{{ currentAlert.title }}</div>
        <div v-if="currentAlert.title === 'System outage'">
          {{ currentAlert.subtitle }}
          <a
            style="text-decoration: underline"
            @click="trackEvent('trigger_login_screen_outages_clicked')"
            target="_blank"
            :href="this.$store.state.account.outageInfo?.outageUrl"
            >Outages</a
          >
          for up to date information.
        </div>
        <div
          v-else-if="
            currentAlert.title ===
            'Refer your friends and family and you could get a $50 statement credit!'
          ">
          {{ currentAlert.subtitle
          }}<router-link
            style="text-decoration: underline"
            to="/account"
            ><span @click="trackEvent('trigger_referral_code_dashboard_banner_clicked')"
              >here</span
            ></router-link
          >
          for your unique referral code.
        </div>
        <div
          v-else-if="
            currentAlert.title ===
            'Join Breezeline Rewards today to access a variety of rewards towards dining, wellness, events and much more!'
          ">
          {{ currentAlert.subtitle }}
          <router-link
            style="text-decoration: underline"
            to="/account"
            ><span @click="trackEvent('trigger_loyalty_dashboard_banner_clicked')"
              >here</span
            ></router-link
          >
          to join now or
          <a
            style="text-decoration: underline"
            target="_blank"
            href="https://breezeline.com/breezeline-rewards"
            >learn more!</a
          >
        </div>
        <div v-else>
          {{ currentAlert.subtitle }}
        </div>
      </div>
      <nav
        v-if="numberOfAlerts > 1"
        class="flex items-end justify-end gap-2"
        aria-label="Pagination">
        <a
          class=""
          href="#"
          @click.prevent="previous()">
          <span class="sr-only">Previous</span>

          <BzlIcon
            :customIconName="icon.name"
            :src="icon.src"
            style="transform: rotate(180deg)"></BzlIcon>
        </a>

        <a
          href="#"
          aria-current="page"
          class=""
          >{{ position + 1 }}</a
        >
        <span class="">of</span>
        <a
          href="#"
          class=""
          >{{ numberOfAlerts }}</a
        >

        <a
          href="#"
          class=""
          @click.prevent="next()">
          <span class="sr-only">Next</span>

          <BzlIcon
            :customIconName="icon.name"
            :src="icon.src"></BzlIcon>
        </a>
      </nav>
    </div>
  </BzlAlert>
</template>

<script>
  import chevronArrow from './chevron.svg'
  export default {
    name: 'BzlSystemNotifications',
    props: {
      open: {
        default: true,
        type: Boolean,
        required: false,
      },
      closeable: {
        default: true,
        type: Boolean,
        required: false,
      },
      alerts: {
        default: () => {
          return []
        },
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        position: 0,
        icon: {
          name: 'Chevron Arrow',
          src: chevronArrow,
        },
      }
    },

    computed: {
      currentAlert() {
        return this.alerts[this.position]
      },
      numberOfAlerts() {
        return this.alerts.length
      },
    },
    methods: {
      next() {
        this.$emit('click-next-notification')

        if (this.position === this.numberOfAlerts - 1) {
          this.position = 0
          return
        }

        this.position++
      },
      previous() {
        this.$emit('click-previous-notification')

        if (this.position === 0) {
          this.position = this.numberOfAlerts - 1
          return
        }

        this.position--
      },
      close() {
        this.$emit('close-system-notifications')
        // manually dismiss the banner
        if (this.currentAlert.title.includes('Refer')) {
          localStorage.setItem('referralBannerDismissed', 'true')
          this.trackEvent('trigger_referral_code_dashboard_banner_dismissed')
        } else if (
          this.currentAlert.title ==
          'Join Breezeline Rewards today to access a variety of rewards towards dining, wellness, events and much more!'
        ) {
          this.trackEvent('trigger_loyalty_dashboard_banner_dismissed')
          localStorage.setItem('loyaltyBannerDismissed', 'true')
        }
      },
    },
  }
</script>

<style></style>
