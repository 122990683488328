let ujet = null
const companyName = 'Charlie, Breezeline Virtual Assistant'
const companyId = import.meta.env.VITE_CCAI_COMPANY_ID
const logo = import.meta.env.VITE_CCAI_LOGO
const host = import.meta.env.VITE_CCAI_HOST
const tenant = import.meta.env.VITE_CCAI_TENANT
let lang = document.getElementsByTagName('html')[0].getAttribute('lang')
let identifier = null
let fullName = null
let ujetStarted = false
const isMobileChannel = 'false'

// TRANSLATION SETTINGS
const translation = {
  es: {
    ujet_greeting:
      'Hola, soy Charlie, tu asistente virtual de Breezeline. Estoy aquí para ayudarte con cualquier pregunta que puedas tener. Haz clic a continuación para comenzar.',
    ujet_message_queue: '',
    ujet_channel_chat: '',
    ujet_message_chat_start: '',
    ujet_common_support: '',
    ujet_chat_transfer_left: '',
    ujet_menu_title: '',
    ujet_common_yes: 'Continuar',
    ujet_common_no: 'Cancelar',
    ujet_chat_end: 'Finalizar Chat',
    ujet_chat_input_placeholder: 'Ingresa tu mensaje aquí',
    ujet_start_title: `Selecciona tu idioma en el menú desplegable superior derecho o haz clic en <b>"Continuar"</b> para proceder en español. <br/><br/> Este chat es solo para información general, no debe utilizarse como asesoramiento legal y puede grabarse con fines de calidad. Al utilizar este chat, usted acepta la grabación y recopilación de sus datos según la política de privacidad de Breezeline: https://es.breezeline.com/policies-agreements`,
    ujet_chat_title_with_one_agent: 'Chatear con {0}',
    ujet_pf_ah_no_deflect:
      "Lamentablemente, nos contactó fuera de horario. ¿Ha consultado las opciones de autoservicio ingresando a 'Mi cuenta' desde la web o la aplicación móvil My Breezeline? Para asistencia inmediata contáctenos al 888-536-9600",
  },
  en: {
    ujet_greeting:
      "Hi, I'm Charlie, your Breezeline Virtual Assistant. I'm here to assist you with any questions you may have. Click below to get started.",
    ujet_message_queue: '',
    ujet_menu_title: '',
    ujet_channel_chat: '',
    ujet_message_chat_start: '',
    ujet_common_support: '',
    ujet_chat_transfer_left: '',
    ujet_common_yes: 'Continue',
    ujet_common_no: 'Cancel',
    ujet_start_title: `Please select your language from the top-right dropdown or click <b>"Continue"</b> to proceed in English. <br /><br /> This chat is for general information only, should not be relied upon for legal advice, and may be recorded for quality purposes. By using this chat, you agree to the recording and collection of your data per Breezeline's Privacy Policy: https://www.breezeline.com/policies-agreements`,
    ujet_chat_title_with_one_agent: 'Chat with {0}',
    ujet_pf_ah_no_deflect:
      "Unfortunately, you have reached us after hours. Have you checked out our self-service options by logging into 'My Account' from the web or My Breezeline Mobile App? For immediate assistance please contact us at 888-536-9600",
  },
}

// // LANGUAGE CHECK
lang === 'es' ? (lang = 'es') : (lang = 'en')

// // ADD CLICK FUNCTIONALITY TO SUPPORT PAGE
export const addClickToSupport = () => {
  // // WEBSITE CHAT
  const chat = document.getElementById('chat')
  chat.style.cursor = 'pointer'

  const chatIconClicked = () => {
    if (!ujetStarted) {
      ujet.start()
      ujetStarted = true
    } else {
      if (ujet.status === 'open') {
        ujet.close()
      } else {
        ujet.open()
      }
    }
    chat.addEventListener('click', function () {
      if (ujet.status === 'open') {
        ujet.close()
      } else {
        ujet.open()
      }
    })
  }
  if (!ujetStarted) {
    chat.addEventListener('click', chatIconClicked, { once: true })
  } else {
    chat.addEventListener('click', function () {
      if (ujet.status === 'open') {
        ujet.close()
      } else {
        ujet.open()
      }
    })
  }
}

// GET TOKEN
// ROUTE: /api/ccai/jwt
function getAuthToken() {
  return fetch(import.meta.env.VITE_CCAI_API + 'api/ccai/jwt', {
    method: 'POST',
    body: JSON.stringify({
      identifier,
      name: fullName,
    }),
  })
    .then((response) => response.text())
    .then((token) => {
      return token
    })
    .catch((err) => {
      console.error('ccaiWidget.js/Fetch Error: ', err)
    })
}

// used to initiate ujest after all customer data has been loaded
export const createUjetObj = (customerInfo) => {
  if (!ujet) {
    fullName = customerInfo.name
    identifier = customerInfo.email

    // eslint-disable-next-line no-undef
    ujet = new UJET({
      name: companyName,
      companyId,
      logo,
      launcher: {
        bottom: '100px',
      },
      host,
      tenant,
      lang,
      translation,
      disableAttachment: true,
      // residential or businbess
      customData: {
        userInformation: {
          label: 'userInformation',
          value: 'resident',
        },
        region: {
          label: 'region',
          value: customerInfo.state,
        },
        isMobileChannel: {
          label: 'isMobileChannel',
          value: isMobileChannel,
        },
      },
      // state
    })
    // get token and then start ujet
    ujet.on('created', function () {
      ujetStarted = true
      getAuthToken().then((token) => {
        ujet.authenticate(token)
      })
    })
    // CLOSE INACTIVE CHAT
    ujet.on('chat:status', function (status) {
      if (status === 'timeout') {
        ujet.close()
      }
    })
  }
}

// ujet loading order
// ujet.start()

// ujet.on('loading')

// ujet.on('created')
