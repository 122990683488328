<template>
  <sl-switch
    v-bind="{ ...$props, ...$attrs }"
    class="BzlSwitch"
    v-on="$listeners"></sl-switch>
</template>

<script>
  // https://shoelace.style/components/switch
  import '@shoelace-style/shoelace/dist/components/switch/switch.js'

  export default {
    name: 'BzlSwitch',
    props: {
      name: {
        type: String,
        default: '',
      },
      value: {
        type: String,
        default: '',
      },
      size: {
        type: String,
        default: 'medium',
        validator: (val) => ['small', 'medium', 'large'].includes(val),
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      checked: {
        type: Boolean,
        default: false,
      },
      defaultChecked: {
        type: Boolean,
        default: false,
      },
      form: {
        type: String,
        default: '',
      },
      required: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>

<style></style>
