<template>
  <div class="mx-auto mt-4 h-96 w-[85rem] max-w-full bg-[#d1d5db]"></div>
</template>

<script>
  export default {
    name: 'Skeleton',
  }
</script>

<style></style>
