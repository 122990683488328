<template>
  <div class="flex h-screen flex-row items-center justify-center">
    <div class="w-1/2 space-y-4 rounded bg-white p-8 text-center shadow">
      <h1 class="text-xl font-bold text-error">
        <i class="fas fa-exclamation-circle"></i> The server encountered an internal error.
      </h1>
      <p>
        Please
        <a
          class="text-navy underline"
          href="/">
          try again
        </a>
        later.
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ServerErrorPage',
  }
</script>
