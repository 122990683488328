<template>
  <sl-badge pill>
    <slot></slot>
  </sl-badge>
</template>

<script>
  import '@shoelace-style/shoelace/dist/components/badge/badge.js'

  export default {
    name: 'AppCountBadge',
  }
</script>
