import { validate } from './validators.js'

export class InternetAddOn {
  constructor(o) {
    if (InternetAddOn.validate(o)) {
      this.id = o.id
      this.category = o.category
      this.title = o.title
      this.titleInCart = o.titleInCart
      this.subtitle = o.subtitle
      this.monthlyPrice = o.monthlyPrice
      this.minQty = o.minQty
      this.maxQty = o.maxQty
      this.dependencies = o.dependencies ? o.dependencies : []
      this.isEditable = o.isEditable ?? true
      this.isVisible = o.isVisible ?? true
    }
  }

  static validate(o) {
    return validate(o, {
      id: ['isDefined', 'isValidString'],
      category: [
        'isDefined',
        'isValidString',
        (val) =>
          ['hardware', 'feature', 'wifi', 'gateway-lease', 'customer-owned-modem'].includes(val),
      ],
      title: ['isDefined', 'isValidString'],
      titleInCart: ['isDefined', 'isValidString'],
      subtitle: ['isValidString'],
      monthlyPrice: ['isDefined', 'isNumber'],
      minQty: ['isNumber'],
      maxQty: ['isNumber'],
      dependencies: ['isArrayOfValidStrings'],
    })
  }
}
