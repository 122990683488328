import axios from 'axios'
import endpoints from './endpoints'
import { DateTime } from 'luxon'
import { prepareInternetServices } from '../utils.js'

export default {
  namespaced: true,
  state: {
    order: null,
  },
  mutations: {
    SET_ORDER(state, order) {
      state.order = order
    },
  },
  getters: {
    originalSelections(state) {
      const selections = {
        internet: null,
        equipment: null,
        wifi: null,
        superpods: null,
      }

      if (
        !state.order.originalOfferConfig.internet ||
        !state.order.originalOfferConfig.equipment ||
        !state.order.originalOfferConfig.wifi ||
        !state.order.originalOfferConfig.superpods
      ) {
        return selections
      }

      selections.internet =
        state.order.originalOfferConfig.internet?.options.find((option) => option.selected)?.name ??
        null

      selections.equipment =
        state.order.originalOfferConfig.equipment?.options.find((option) => option.selected)
          ?.name ?? null

      selections.wifi =
        state.order.originalOfferConfig.wifi?.options.find((option) => option.selected)?.name ??
        null

      selections.superpods =
        state.order.originalOfferConfig.superpods?.options.find((option) => option.quantity > 0)
          ?.name ?? null

      return selections
    },
    superpodsQuantity(state) {
      return state.order.originalOfferConfig.superpods?.options[0]?.quantity ?? null
    },
  },
  actions: {
    async initialize({ rootState, commit, dispatch, state }, accountNumber) {
      // todo move the account initialize stuff here
      await dispatch('refreshOrder')
      await dispatch('updateState')
    },
    async getSummary({ rootState, commit, dispatch, state }) {
      dispatch('lockApp', null, { root: true })

      const params = {
        uuid: rootState.account.uuid,
        conversationId: rootState.account.conversationId,
      }

      return axios
        .get(endpoints.GET_SUMMARY, { params })
        .then((response) => {
          commit('SET_ORDER', response.data.data)
          dispatch('updateState')
        })
        .catch((error) => {
          if (error.response.data.message === 'Invalid conversationId') {
            throw new Error(error.response.data.message, { cause: 'conversationId' })
          }

          throw new Error(error.response.data.message, { cause: 'api' })
        })
        .finally((response) => {
          dispatch('unlockApp', null, { root: true })
        })
    },
    async getAppointments({ rootState, commit, dispatch, state }) {
      // todo move access to these params to this store
      const fromDate = DateTime.local().toISO()
      const toDate = DateTime.local().plus({ months: 1 }).toISO()

      const params = {
        uuid: rootState.account.uuid,
        conversationId: rootState.account.conversationId,
        fromDate,
        toDate,
      }

      const selfInstallKitAdded = state.order.installation.options.find((option) => {
        return option.id === 'sik-ship-direct'
      })

      if (selfInstallKitAdded) {
        params.noTruck = true
      }

      const changeOfServiceNoTruckAdded = state.order.installation.options.find((option) => {
        return option.id === 'change-of-service-no-truck'
      })

      if (changeOfServiceNoTruckAdded) {
        params.noTruck = true
      }

      dispatch('lockApp', null, { root: true })

      return axios
        .get(endpoints.GET_APPOINTMENTS, { params })
        .then((response) => {
          commit('SET_ORDER', response.data.data)
          return response.data.data
        })
        .catch((error) => {
          if (error.response.data.message === 'Invalid conversationId') {
            throw new Error(error.response.data.message, { cause: 'conversationId' })
          }

          throw new Error(error.response.data.message, { cause: 'api' })
        })
        .finally(() => {
          dispatch('unlockApp', null, { root: true })
        })
    },
    async submitAppointment({ dispatch, rootState }, { id, date, timeSlot }) {
      const data = {
        uuid: rootState.account.uuid,
        conversationId: rootState.account.conversationId,
        id,
        date,
        timeSlot,
      }

      dispatch('lockApp', null, { root: true })

      return axios
        .post(endpoints.POST_APPOINTMENTS, data)
        .then((response) => {})
        .catch((error) => {
          if (error.response.data.message === 'Invalid conversationId') {
            throw new Error(error.response.data.message, { cause: 'conversationId' })
          }

          throw new Error(error.response.data.message, { cause: 'api' })
        })
        .finally(() => {
          dispatch('unlockApp', null, { root: true })
        })
    },
    async refreshOrder({ commit, state, rootState, dispatch }) {
      dispatch('lockApp', null, { root: true })

      if (!rootState.account.uuid || !rootState.account.conversationId) {
        return false
      }

      const params = {
        uuid: rootState.account.uuid,
        conversationId: rootState.account.conversationId,
      }

      return axios
        .get(endpoints.GET_ORDER, { params })
        .then((response) => {
          commit('SET_ORDER', response.data.data)
        })
        .then(() => {
          dispatch('unlockApp', null, { root: true })
        })
    },
    async getCart({ dispatch, commit, state, rootState }, { silent = false }) {
      if (!silent) {
        dispatch('lockApp', null, { root: true })
      }

      const params = {
        uuid: rootState.account.uuid,
        conversationId: rootState.account.conversationId,
      }

      return axios
        .get(endpoints.GET_CART, { params })
        .then((response) => {})
        .catch((error) => {
          if (error.response.data.message === 'Invalid conversationId') {
            throw new Error(error.response.data.message, { cause: 'conversationId' })
          }

          throw new Error(error.response.data.message, { cause: 'api' })
        })
        .then((response) => {
          dispatch('unlockApp', null, { root: true })
        })
    },

    async addInitialOffers({ commit, state, rootState, dispatch }) {
      commit('LOCK_APP', null, { root: true })

      const data = {
        uuid: rootState.account.uuid,
        conversationId: rootState.account.conversationId,
        offer: parseInt(rootState.account.currentOffer),
        equipment: [],
      }

      // we can't submit offers if we don't know what products we have available
      // so go out and get them
      if (!rootState.products.options) {
        return false
      }

      // try and load the current internet offer configuration from local
      // storage and if it's not there then call out to the api
      const originalInternetOfferConfig = window.localStorage.getItem('originalInternetOfferConfig')
      if (originalInternetOfferConfig) {
        commit(
          'internet/SET_SERVICES',
          prepareInternetServices(
            JSON.parse(originalInternetOfferConfig),
            state.order.credits?.options
          ),
          { root: true }
        )

        commit('UNLOCK_APP', null, { root: true })
      } else {
        // submit the offers
        return axios
          .post(endpoints.ADD_OFFERS, data)
          .then((response) => {
            if (response.data.data) {
              commit('SET_ORDER', response.data.data)
              const services = response.data.data.internet?.options

              if (!services) {
                throw new Error('Internet options were not provided', { cause: 'api' })
              }

              // store the internet offer configuration from the api in local storage
              window.localStorage.setItem('originalInternetOfferConfig', JSON.stringify(services))
            }

            commit('UNLOCK_APP', null, { root: true })
          })
          .catch((error) => {
            throw new Error(error.message, { cause: 'api' })
          })
      }
    },

    async determineShipping({ commit, dispatch, state, rootState }) {
      dispatch('lockApp', null, { root: true })

      const params = {
        uuid: rootState.account.uuid,
        conversationId: rootState.account.conversationId,
        upgrade_required: rootState.internet.requiresModemUpgrade,
      }

      return axios
        .get(endpoints.GET_SHIPPING, { params })
        .then((response) => {
          commit('SET_ORDER', response.data.data)
        })
        .catch((error) => {
          throw new Error(error.response.data.message, {
            cause: 'api',
          })
        })
        .then(() => {
          dispatch('unlockApp', null, { root: true })
        })
    },

    async determineCredits({ commit, dispatch, state, rootState }) {
      dispatch('lockApp', null, { root: true })

      const params = {
        uuid: rootState.account.uuid,
        conversationId: rootState.account.conversationId,
        upgrade_required: rootState.internet.requiresModemUpgrade,
      }

      return axios
        .get(endpoints.GET_CREDITS, {
          params,
        })
        .then((response) => {
          commit('SET_ORDER', response.data.data)
        })
        .catch((error) => {
          throw new Error(error.response.data.message, {
            cause: 'api',
          })
        })
        .then(() => {
          dispatch('unlockApp', null, {
            root: true,
          })
        })
    },

    async fetchOtherServices({ commit, dispatch, state, rootState }) {
      const params = {
        uuid: rootState.account.uuid,
        conversationId: rootState.account.conversationId,
      }

      try {
        if (!state.order.additionalChannels) {
          await axios.get(endpoints.GET_ADDITIONAL_CHANNELS, { params })
        }

        if (!state.order.additionalEquipment) {
          await axios.get(endpoints.GET_ADDITIONAL_EQUIPMENT, { params })
        }

        if (!state.order.locals) {
          await axios.get(endpoints.GET_LOCALS, { params })
        }

        if (!state.order.streamingServices) {
          await axios.get(endpoints.GET_STREAMING_SERVICES, { params })
        }

        if (!state.order.tivo) {
          await axios.get(endpoints.GET_TIVO, { params })
        }

        if (!state.order.voice) {
          await axios.get(endpoints.GET_VOICE, { params })
        }

        await dispatch('initialize')
      } catch (error) {
        throw new Error(error.response.data.message, { cause: 'api' })
      }
    },
    async updateState({ commit, dispatch, state, rootState, getters }) {
      if (!state.order) {
        return
      }

      if (state.order.equipment) {
        dispatch('equipment/updateEquipmentFromOfferConfig', state.order.equipment.options, {
          root: true,
        })
      }

      if (state.order.wifi) {
        await dispatch(
          'equipment/updateWiFiYourWayOption',
          state.order.originalOfferConfig.wifi.options,
          { root: true }
        )
      }

      if (state.order.superpods) {
        await dispatch('equipment/updateAdditionalPodsOption', state.order.superpods.options, {
          root: true,
        })
      }

      if (state.order.products) {
        commit('products/SET_OPTIONS', state.order.products, { root: true })
      }

      let credits = null

      if (state.order.credits?.options.length) {
        credits = state.order.credits.options
      }

      if (state.order.originalOfferConfig.internet) {
        const originallySelected = prepareInternetServices(
          state.order.originalOfferConfig.internet.options,
          credits
        )

        const newlySelected = prepareInternetServices(state.order.internet.options, credits)

        commit('internet/SET_SERVICES', originallySelected, { root: true })

        newlySelected.allServices.forEach((service) => {
          if (service.id === newlySelected.currentServiceId) {
            dispatch('cart/setService', { type: 'internet', service }, { root: true })
          }
        })
      }

      const gatewayLeaseInCart = rootState.cart.addOns.internet.find(
        (addOn) => addOn[0].id === rootState.equipment.gatewayLease.id
      )

      if (rootState.internet.requiresModemUpgrade && gatewayLeaseInCart) {
        dispatch(
          'cart/addAddOn',
          {
            type: 'installation',
            addOn: rootState.equipment.selfInstallationKit,
            quantity: 1,
          },
          {
            root: true,
          }
        )
      }

      if (gatewayLeaseInCart && rootState.internet.requiresModemUpgrade && state.order.wifi) {
        state.order.wifi.options.forEach((option) => {
          if (option.selected) {
            // if the title is the same as the originally selected wifi, make it
            // invisible in the cart
            dispatch(
              'cart/addAddOn',
              {
                type: 'wifi',
                addOn: rootState.equipment.wifiYourWay,
              },
              { root: true }
            )
          }
        })
      }

      // if we've got superpods options, lets add them to the cart
      if (state.order.superpods) {
        // loop through the options though there is usually only one option for
        // superpods
        state.order.superpods.options.forEach((option) => {
          // if the quantity is greater than zero, add it to the cart.
          if (option.quantity > 0) {
            // however, we only want the item in the cart visible in the summary
            // if it's different than what has been selected.
            if (getters.superpodsQuantity !== option.quantity) {
              rootState.equipment.additionalPods.isVisible = true
            }

            dispatch(
              'cart/addAddOn',
              {
                type: 'internet',
                addOn: rootState.equipment.additionalPods,
                quantity: option.quantity,
              },
              { root: true }
            )
          }
        })
      }

      if (state.order.orderSummary) {
        commit('cart/SET_LOCAL_TAX', state.order.orderSummary.taxFees, { root: true })
      }
    },
    async submitOfferConfiguration({ commit, dispatch, state, rootState }) {
      dispatch('lockApp', null, { root: true })

      const data = {
        uuid: rootState.account.uuid,
        conversationId: rootState.account.conversationId,
      }

      return axios
        .post(endpoints.POST_OFFER_CONFIGURATION, data)
        .then((response) => {
          commit('SET_ORDER', response.data.data)
          dispatch('updateState')
        })
        .catch((error) => {
          if (error.response.data.message === 'Invalid conversationId') {
            throw new Error(error.response.data.message, { cause: 'conversationId' })
          }

          throw new Error(error.response.data.message, { cause: 'api' })
        })
        .then(() => {
          dispatch('unlockApp', null, { root: true })
        })
    },
    async submitOrder({ commit, dispatch, rootState }) {
      dispatch('lockApp', null, { root: true })

      const data = {
        uuid: rootState.account.uuid,
        conversationId: rootState.account.conversationId,
        planId: rootState.BroadbandLabels.planId,
        accountId: rootState.account.accountNumber,
      }

      return axios
        .post(endpoints.POST_ORDER, data)
        .then((res) => {
          // what do to do, what to do?
        })
        .catch((error) => {
          if (error.response.data.message === 'Invalid conversationId') {
            throw new Error(error.response.data.message, { cause: 'conversationId' })
          }

          throw new Error(error.response.data.message, { cause: 'api' })
        })
        .then(() => {
          dispatch('unlockApp', null, { root: true })
        })
    },
  },
}
