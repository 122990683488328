<template>
  <sl-icon
    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners"></sl-icon>
</template>

<script>
  import '@shoelace-style/shoelace/dist/components/icon/icon.js'
  export default {
    name: 'BzlIcon',
    props: {
      // name is used for native shoelace icons
      name: {
        type: [Object, String],
        required: false,
      },
      // customIconName is used for custom icons (svgs, icons wrapped in vue templates, etc)
      customIconName: {
        type: [Object, String],
        required: false,
      },
      src: {
        type: String,
        required: false,
        default: null,
      },
      label: {
        type: String,
        required: false,
        default: null,
      },
    },
  }
</script>
