<template>
  <SiteLoader></SiteLoader>
</template>

<script>
  import SiteLoader from '../apps/easypay/components/SiteLoader/index.vue'

  export default {
    name: 'LoadingPage',
    components: {
      SiteLoader,
    },
  }
</script>

<style scoped></style>
