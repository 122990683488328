<template>
  <div class="space-y-6">
    <div class="text-lg font-bold uppercase">{{ title }}</div>
    <hr />
    <div class="space-y-2">
      <div class="text-lg font-bold">Service</div>
      <div class="line-item">
        <span>{{ servicesTitle }}</span>
        <span class="font-bold">{{ formatPrice(servicesSubtotal) }}</span>
      </div>
      <div
        v-if="hasServices"
        class="pool">
        <div
          v-if="services.internet"
          class="line-item">
          <span>
            {{ services.internet.titleInCart }}
            <RouterLink
              class="edit-link"
              :to="{ name: 'change-your-speed' }">
              edit
            </RouterLink>
          </span>
          <span>{{ formatPrice(services.internet.monthlyPrice) }}</span>
        </div>
      </div>
    </div>
    <hr />
    <div
      v-if="hasVisibleAddOns"
      class="space-y-2">
      <div class="line-item text-lg font-bold">
        <span>Add Ons</span>
        <span>{{ formatPrice(addOnsSubtotal) }}</span>
      </div>
      <div class="pool">
        <div v-if="hasVisibleInternetAddOns">
          <div class="line-item">
            <strong>Internet</strong>
          </div>
          <div class="space-y-1">
            <div
              v-for="addOn in visibleInternetAddOns"
              :key="addOn[0].id"
              class="line-item">
              <span>
                {{ addOn[0].titleInCart }}
                <AppCountBadge v-if="addOn[1] > 1">
                  {{ addOn[1] }}
                </AppCountBadge>
                <RouterLink
                  v-if="hardwareEditable && addOn[0].isEditable"
                  class="edit-link"
                  :to="{ name: 'hardware-requirement' }">
                  edit
                </RouterLink>
              </span>
              <span>{{ formatPrice(addOn[0].monthlyPrice * addOn[1]) }}</span>
            </div>
          </div>
        </div>
        <div v-if="hasVisibleInstallationAddons">
          <div class="line-item">
            <strong>Installation</strong>
          </div>
          <div class="space-y-1">
            <div
              v-for="addOn in visibleInstallationAddOns"
              :key="addOn[0].id"
              class="line-item">
              <span>
                {{ addOn[0].titleInCart }}
                <AppCountBadge v-if="addOn[1] > 1">
                  {{ addOn[1] }}
                </AppCountBadge>
                <RouterLink
                  v-if="hardwareEditable && addOn[0].isEditable"
                  class="edit-link"
                  :to="{ name: 'hardware-requirement' }">
                  edit
                </RouterLink>
              </span>
              <span>{{ formatPrice(addOn[0].monthlyPrice * addOn[1]) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr v-if="hasVisibleAddOns" />

    <div>
      <p>
        The estimated taxes cannot be shown at this time, but will appear in your order confirmation
        email.
      </p>
    </div>
    <hr />
    <div class="text-right">
      <div class="text-xl">
        Internet Monthly Cost*:
        <span class="font-bold">{{ formatPrice(totalMonthlyPrice) }}/mo</span>
      </div>
      <div class="text-sm">
        All fees included
        <BzlIconButton
          name="info-circle-fill"
          class="text-sm opacity-60"
          label="All fees included disclaimer"
          @click="modals.allFeesIncludedDisclaimer = true"></BzlIconButton>
      </div>
    </div>
    <hr />
    <div class="text-right">
      <div class="font-bold uppercase">Straight Up Pricing</div>
      <div class="text-sm">
        <p class="mb-4">
          Besides regulatory fees and taxes beyond our control, all fees are included so you know
          what your bill will look like next month, next year, and beyond.
        </p>

        <p>
          *The above price is the new monthly price for your selected Internet Services only. It
          does not include any monthly charges for any Video Services or Voice Services that you may
          also subscribe to, and such charges will also be included on your next invoice.
        </p>
      </div>
    </div>
    <BzlModal
      :open="modals.allFeesIncludedDisclaimer"
      label="All fees included"
      @sl-after-hide="modals.allFeesIncludedDisclaimer = false">
      <div class="space-y-4">
        <p v-if="promoDurationInMonths">
          {{ promoDurationInMonths }} months promotional price.<br />Everyday price after promotion:
          {{ formatPrice(postPromoTotalMonthlyPrice) }}/mo
        </p>
        <p v-else>No promotion term applied.</p>
      </div>
    </BzlModal>
  </div>
</template>

<script>
  export default {
    name: 'AppCart',
    props: {
      title: {
        type: String,
        required: false,
        default: 'Your Cart',
      },
      cartData: {
        // no need to validate via a model
        type: Object,
        required: true,
      },
      promoDurationInMonths: {
        type: Number,
        required: false,
        default: 0,
      },
      postPromoTotalMonthlyPrice: {
        type: Number,
        required: false,
        default: 0,
      },
      hardwareEditable: {
        type: Boolean,
        required: false,
        default: true,
      },
    },
    data: () => ({
      modals: {
        allFeesIncludedDisclaimer: false,
      },
    }),
    computed: {
      services() {
        return this.cartData.services
      },
      addOns() {
        return this.cartData.addOns
      },
      hasServices() {
        return this.services.internet || this.services.tv || this.services.voice
      },
      hasVisibleAddOns() {
        return (
          this.addOns.internet.filter((addOn) => addOn[0].isVisible).length > 0 ||
          this.addOns.wifi.filter((addOn) => addOn[0].isVisible).length > 0 ||
          this.addOns.installation.filter((addOn) => addOn[0].isVisible).length > 0
        )
      },
      hasVisibleInternetAddOns() {
        return (
          this.addOns.internet.filter((addOn) => addOn[0].isVisible).length > 0 ||
          this.addOns.wifi.filter((addOn) => addOn[0].isVisible).length > 0
        )
      },
      hasVisibleInstallationAddons() {
        return this.addOns.installation.filter((addOn) => addOn[0].isVisible).length > 0
      },
      visibleInternetAddOns() {
        return [
          ...this.addOns.internet.filter((addOn) => addOn[0].isVisible),
          ...this.addOns.wifi.filter((addOn) => addOn[0].isVisible),
        ]
      },
      visibleInstallationAddOns() {
        return [...this.addOns.installation.filter((addOn) => addOn[0].isVisible)]
      },
      servicesTitle() {
        const title = []
        if (this.services.internet) title.push('Internet')
        if (this.services.tv) title.push('TV')
        if (this.services.voice) title.push('Voice')
        return title.join(' + ') || 'No services'
      },
      servicesSubtotal() {
        let subtotal = 0
        subtotal += this.services.internet?.monthlyPrice || 0
        subtotal += this.services.tv?.monthlyPrice || 0
        subtotal += this.services.voice?.monthlyPrice || 0
        return subtotal
      },
      addOnsGroups() {
        return Object.keys(this.addOns)
      },
      addOnsSubtotal() {
        let subtotal = 0

        for (const group of this.addOnsGroups) {
          for (const addOn of this.addOns[group]) {
            // only showing pricing for visible addons
            if (addOn[0].isVisible && addOn[1] > 0) {
              subtotal += addOn[0].monthlyPrice * addOn[1]
            }
          }
        }

        return subtotal
      },
      totalMonthlyPrice() {
        return this.servicesSubtotal + this.addOnsSubtotal
        // + this.cartData.localTax <-- disabling tax displayed for now. it's
        //   not being returned properly from csg for many accounts due to
        //   credits/adjustments, often time coming back as a negative number.
        //   plus the only time it should be shown according to raven is when
        //   the user is adding a modem lease
      },
      formatPrice: () => (price) => {
        return `$${Number(price).toFixed(2)}`
      },
      capitalize: () => (str) => {
        // todo: move to mixins
        return str[0].toUpperCase() + str.slice(1)
      },
      routeName() {
        return this.$route.name
      },
    },
  }
</script>

<style scoped>
  .line-item {
    @apply flex flex-row justify-between text-sm xl:text-base;
  }

  .edit-link {
    @apply text-xs text-salmon underline opacity-80 hover:opacity-100 xl:text-sm;
  }

  .pool {
    @apply space-y-4 rounded-md bg-gray-light p-4;
  }
</style>
