<template>
  <div
    class="fixed bottom-0 left-0 right-0 z-50 w-full shadow-rev"
    :class="colorClasses">
    <div
      class="relative z-50 flex flex-row items-center space-x-4 p-4"
      :class="innerClasses">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AppWizardNav',
    props: {
      color: {
        type: String,
        default: 'default',
        validator: (val) => ['default', 'dark'].includes(val),
      },
    },
    computed: {
      innerClasses() {
        return this.$attrs['inner-class'] || `justify-between`
      },
      colorClasses() {
        return {
          default: `bg-white`,
          dark: `bg-blue`,
        }[this.color]
      },
    },
  }
</script>
