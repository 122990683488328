<template>
  <div
    class="text-navy-dark"
    :class="propClasses"
  >
    <h1 class="text-xl font-bold leading-tight lg:text-2xl">
      <slot name="title"></slot>
    </h1>
    <div
      v-if="$slots.subtitle"
      class="text-sm font-bold"
    >
      <slot name="subtitle"></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'BzlTitlePair',
    props: {
      centered: {
        type: Boolean,
        required: false,
        default: false,
      },
      tight: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    computed: {
      propClasses() {
        const propClasses = []

        if (this.centered) propClasses.push('text-center')
        if (this.tight) propClasses.push('leading-tight')

        return propClasses.join(' ')
      },
    },
  }
</script>
