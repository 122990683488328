import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloLink, concat } from 'apollo-link'
import { onError } from 'apollo-link-error'

const errhandler = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    console.error('graphQLErrors', graphQLErrors)
    window.Sentry?.captureException(graphQLErrors)
  }

  if (networkError) {
    console.error('networkError', networkError)
    window.Sentry?.captureException(networkError)
  }
})

let csrf

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      'x-csrf-token': csrf,
    },
    connectToDevTools: true,
    credentials: 'include',
  })

  return forward(operation).map((response) => {
    csrf = operation.getContext().response.headers.get('x-csrf-token') ?? csrf
    return response
  })
})

const httpLink = createHttpLink({
  uri: import.meta.env.VITE_APOLLO_URL,
})

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authLink, concat(errhandler, httpLink)),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    mutate: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})
const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
})

Vue.use(VueApollo)

export { apolloProvider }
