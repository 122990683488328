import Vue from 'vue'
import VueRouter from 'vue-router'

import { isUnderMaintenance } from './maintenance.js'
import { login } from './auth.js'

import './mixins.js'
import './index.css'

Vue.config.productionTip = false

let root = null

if (process.env.NODE_ENV !== 'production') {
  console.log(`%c⚠️ we're running in ${process.env.NODE_ENV} mode ⚠️`, 'background-color: #475569;')
}

export async function loadMainApp({ app = null, router = [], store = {}, authType = null }) {
  if (app && !isUnderMaintenance(loadAuxPage('maintenance'))) {
    const rootConfig = {
      router,
      store,
      render: (h) => h(app),
    }

    const loginResult = await login(authType, {
      toCookieAccessErrorPage: loadAuxPage('cookie-access-error'),
      toServerErrorPage: loadAuxPage('server-error'),
      toLoadingPage: loadAuxPage('loading'),
    })

    // console.log(loginResult);
    // Check if login function is returning an object
    if (typeof loginResult === 'object' && loginResult !== null) {
      const { user = null, apolloProvider = null } = loginResult

      rootConfig.data = () => ({ user })
      rootConfig.apolloProvider = apolloProvider

      root = new Vue(rootConfig).$mount('#app')
    } else {
      // Handle the case where loginResult is not an object
      console.error('loginResult is not an object:', loginResult)
    }
  }
}

function loadAuxPage(page) {
  return async () => {
    if (!root) {
      Vue.use(VueRouter)

      const app = (await import('./App.vue')).default

      root = new Vue({
        router: new VueRouter({
          mode: 'history',
          base: '/',
          routes: (await import('./routes')).default,
        }),
        render: (h) => h(app),
      }).$mount('#app')
    }

    root.$children[0]?.routeTo(page)
  }
}
